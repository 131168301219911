import { useState, useCallback } from "react";
import { BrowserProvider } from "ethers";

declare global {
  interface Window {
    ethereum?: any;
  }
}

const useWallet = () => {
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [chainId, setChainId] = useState<number | null>(null);
  const [networkName, setNetworkName] = useState<string | null>(null);

  const connectWallet = useCallback(async () => {
    if (window.ethereum) {
      const provider = new BrowserProvider(window.ethereum);
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });

        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);

        const network = await provider.getNetwork();

        setChainId(Number(network.chainId));
        setNetworkName(network.name);
      } catch (error) {
        console.error("User denied account access", error);
      }
    } else {
      alert("Please install MetaMask!");
    }
  }, []);

  const disconnectWallet = () => {
    setWalletAddress(null);
    setChainId(null);
    setNetworkName(null);
    console.log("Wallet disconnected");
  };

  return { walletAddress, chainId, networkName, connectWallet, disconnectWallet };
};

export default useWallet;
