import React, { useEffect, useRef, useState } from "react";
import {
  nightToggleStyle,
  iconStyle,
  fullHeightStyle,
  authenticatorContainerStyle,
  loadMoreButtonStyle,
} from "../styles/styles";
import { setIntegratorId } from "../services/session";
import { fetchDashboardData } from "../services/api";
import { IconMoon, IconMoonFilled } from "@tabler/icons-react";
import TopBanner from "./TopBanner";

interface AuthenticatorProps {
  children: React.ReactNode;
}

const Authenticator: React.FC<AuthenticatorProps> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [integratorId, setIntegratorIdState] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isNightMode, setIsNightMode] = useState<boolean>(() => {
    const localStorageValue = localStorage.getItem("isNightMode");
    return localStorageValue !== null ? localStorageValue === "true" : true;
  });

  const toggleNightMode = () => {
    setIsNightMode(!isNightMode);
    localStorage.setItem("isNightMode", (!isNightMode).toString());
  };

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--bg-color", isNightMode ? "#1b212b" : "#fff");
    root.style.setProperty("--text-color", isNightMode ? "#fff" : "#333");
    root.style.setProperty("--text2-color", isNightMode ? "#333" : "#fff");
    root.style.setProperty("--time-color", isNightMode ? "#fff" : "#555");
    root.style.setProperty("--search-color", isNightMode ? "#666" : "#888");
    root.style.setProperty("--link-color", isNightMode ? "lightBlue" : "blue");
    root.style.setProperty("--accent-color", isNightMode ? "#e3f734" : "#d5bef4");
    root.style.setProperty("--accent2-color", isNightMode ? "#d5bef4" : "#e3f734");
    root.style.setProperty("--shadow-color", isNightMode ? "#13171f" : "#f9f9f9");

    if (localStorage.getItem("isNightMode") === null) {
      localStorage.setItem("isNightMode", "true");
    }
  }, [isNightMode]);

  useEffect(() => {
    const storedIntegratorId = process.env.REACT_APP_INTEGRATOR_ID || "";
    if (storedIntegratorId) {
      setIntegratorIdState(storedIntegratorId);
      validateIntegratorId(storedIntegratorId);
    } else {
      setLoading(false);
    }
  }, []);

  const validateIntegratorId = async (id: string) => {
    setLoading(true);
    try {
      await fetchDashboardData();
      setIntegratorId(id);
      setAuthenticated(true);
      setError(null);
    } catch (err) {
      console.error("Error verifying integrator ID:", err);
      setError("Invalid integrator ID. Please try again.");
      setAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIntegratorId(integratorId);
    validateIntegratorId(integratorId);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIntegratorIdState(event.target.value);
    setError(null);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement !== inputRef.current) {
        setIsFocused(false);
      }
    }, 100);
  };

  if (isLoading) {
    return (
      <div
        style={{
          ...authenticatorContainerStyle,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h2>Loading...</h2>
      </div>
    );
  }

  return authenticated && !error ? (
    <>{children}</>
  ) : (
    <div style={fullHeightStyle}>
      <TopBanner />
      <div
        style={{
          ...authenticatorContainerStyle,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div style={nightToggleStyle}>
          {isNightMode ? (
            <IconMoonFilled
              width={16}
              height={16}
              style={{ color: "#e3f734", cursor: "pointer", ...iconStyle }}
              onClick={toggleNightMode}
            />
          ) : (
            <IconMoon
              width={16}
              height={16}
              style={{ color: "#d5bef4", cursor: "pointer", ...iconStyle }}
              onClick={toggleNightMode}
            />
          )}
        </div>
        <h2>Login Required</h2>
        <p>Please enter your Squid integrator ID to access.</p>
        <form onSubmit={handleLogin} style={{ textAlign: "center" }}>
          <input
            ref={inputRef}
            type="text"
            placeholder="Enter Integrator ID"
            value={integratorId}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            style={{
              width: "600px",
              transition: "width 0.3s ease-in-out, left 0.3s ease-in-out",
              padding: "10px 40px 10px 10px",
              borderRadius: "8px",
              border: `1px solid ${isFocused ? "var(--accent-color)" : "var(--text2-color)"}`,
              backgroundColor: "var(--bg-color)",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              color: "var(--text-color)",
              fontSize: "16px",
            }}
          />
          <button type="submit" style={loadMoreButtonStyle}>
            Login
          </button>
        </form>
        {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
      </div>
    </div>
  );
};

export default Authenticator;
