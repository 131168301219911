import React, { useState } from "react";
import { inputStyle, filterInputStyle, listItemStyle } from "../styles/styles";
import { FilterInputProps } from "../types";

const FilterInput: React.FC<FilterInputProps> = ({ value, onChange, options, placeholder }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleFocus = () => setShowDropdown(true);
  const handleBlur = () => setTimeout(() => setShowDropdown(false), 100);

  const handleOptionClick = (option: string) => {
    onChange(option);
    setShowDropdown(false);
  };

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(value.toLowerCase()),
  );

  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        style={{ ...inputStyle }}
      />
      {showDropdown && (
        <ul style={filterInputStyle}>
          {filteredOptions.map(option => (
            <li
              key={option}
              style={listItemStyle}
              onMouseOver={e => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
              onMouseOut={e => (e.currentTarget.style.backgroundColor = "transparent")}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterInput;
