import axios from "axios";
import {
  BlockTimestamps,
  DebridgeQuote,
  FetchAcrossParams,
  FetchDebridgeParams,
  FetchOrbiterParams,
  FetchRelayParams,
  FetchRouterNitroParams,
  FetchStargateParams,
  FetchSymbiosisParams,
  FetchSynapseParams,
  OrbiterQuote,
  OrderStatus,
  RelayQuote,
  RFQEventData,
  RouteRequest,
  RouterNitroQuote,
  SquidRFQOrder,
  StargateQuote,
  SuggestedFeesResponse,
  SymbiosisQuote,
  SynapseQuote,
  Token,
  TransactionHashes,
} from "../types";
import { isOrderCreatedEvent } from "../utils/validation";
import { getIntegratorId, getScanIntegratorId } from "../services/session";
import { getApiUrl } from "../utils/constants";
import { ethers } from "ethers";
import stargateAbi from "../abis/stargate.json";

interface ApiResponse {
  events: any[];
  tokens: any[];
}

export const fetchDashboardData = async (
  onlyEvents = false,
): Promise<{ events: RFQEventData[]; tokens: any[] }> => {
  try {
    const apiUrl = getApiUrl();
    const params = new URLSearchParams({
      onlyEvents: String(onlyEvents),
    });

    const response = await axios.get<ApiResponse>(`${apiUrl}/rfq/dashboard?${params.toString()}`, {
      headers: {
        "Content-Type": "application/json",
        "x-integrator-id": getIntegratorId(),
      },
    });

    const { events, tokens } = response.data;

    const eventsByOrderHash: Record<string, RFQEventData> = events.reduce(
      (acc, event) => {
        if (!acc[event.orderHash]) {
          acc[event.orderHash] = {
            orderCreated: null,
            statuses: {
              OrderFilled: false,
              OrderRefunded: false,
              SettlementForwarded: false,
              TokensReleased: false,
              SettlementFilled: false,
              SettlementProcessed: false,
            },
            transactionHashes: {
              OrderCreated: null,
              OrderFilled: null,
              OrderRefunded: null,
              SettlementForwarded: null,
              TokensReleased: null,
              SettlementFilled: null,
              SettlementProcessed: null,
            },
            blockTimestamps: {
              OrderCreated: null,
              OrderFilled: null,
              OrderRefunded: null,
              SettlementForwarded: null,
              TokensReleased: null,
              SettlementFilled: null,
              SettlementProcessed: null,
            },
          };
        }

        if (isOrderCreatedEvent(event)) {
          acc[event.orderHash].orderCreated = event;
          acc[event.orderHash].transactionHashes.OrderCreated = event.transactionHash;
          acc[event.orderHash].blockTimestamps.OrderCreated = event.blockTimestamp;
        } else if (event.type in acc[event.orderHash].statuses) {
          const eventType = event.type as keyof OrderStatus;
          acc[event.orderHash].statuses[eventType] = true;
          acc[event.orderHash].transactionHashes[eventType as keyof TransactionHashes] =
            event.transactionHash;
          acc[event.orderHash].blockTimestamps[eventType as keyof BlockTimestamps] =
            event.blockTimestamp;
        }

        return acc;
      },
      {} as Record<string, RFQEventData>,
    );

    const filteredEvents: RFQEventData[] = Object.values(eventsByOrderHash).filter(
      event => event.orderCreated !== null,
    );

    filteredEvents.sort((a, b) => {
      const timestampA = a.orderCreated ? parseInt(a.orderCreated.blockTimestamp, 10) : 0;
      const timestampB = b.orderCreated ? parseInt(b.orderCreated.blockTimestamp, 10) : 0;
      return timestampB - timestampA;
    });

    return { events: filteredEvents, tokens };
  } catch (error) {
    console.error("Error fetching Dashboard data:", error);
    throw error;
  }
};

export const fetchPostHooks = async (
  orderHashes: string[],
): Promise<{ calls: any; callData: any; gasLimit: number }> => {
  try {
    const apiUrl = getApiUrl();
    const response = await axios.post(
      `${apiUrl}/rfq/post-hooks`,
      { orderHashes },
      {
        headers: {
          "Content-Type": "application/json",
          "x-integrator-id": getIntegratorId(),
        },
      },
    );

    const postHooks = response.data.postHooks[0];

    return { calls: postHooks.calls, callData: postHooks.callData, gasLimit: postHooks.gasLimit };
  } catch (error) {
    console.error("Error fetching Post hooks:", error);
    throw error;
  }
};

export const fetchOrder = async (hash: string): Promise<SquidRFQOrder | null> => {
  try {
    const apiUrl = getApiUrl();
    const response = await axios.post<SquidRFQOrder>(
      `${apiUrl}/rfq/order`,
      { hash: hash.toLowerCase() },
      {
        headers: {
          "Content-Type": "application/json",
          "x-integrator-id": getScanIntegratorId(),
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching order:", error);
    throw error;
  }
};

export const fetchLatestOrders = async (
  index?: number,
  address?: string,
): Promise<SquidRFQOrder[] | null> => {
  try {
    const apiUrl = getApiUrl();
    const response = await axios.post<SquidRFQOrder[]>(
      `${apiUrl}/rfq/latest-orders`,
      { index: index, address: address.toLowerCase() },
      {
        headers: {
          "Content-Type": "application/json",
          "x-integrator-id": getScanIntegratorId(),
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching order:", error);
    throw error;
  }
};

export const fetchTokens = async (): Promise<Token[] | undefined> => {
  try {
    const apiUrl = getApiUrl();
    return await axios
      .get(`${apiUrl}/tokens`, {
        headers: {
          "X-integrator-Id": getScanIntegratorId(),
        },
      })
      .then(res => {
        if (!res.data) {
          return undefined;
        }
        return res.data.tokens;
      });
  } catch (error) {
    console.error("Error fetching tokens:", error);
    return undefined;
  }
};

export const fetchRoute = async (
  request: RouteRequest,
  apiUrl: string,
  integratorId: string,
  options: { signal?: AbortSignal } = {},
): Promise<any | null> => {
  try {
    const response = await axios.post<any>(
      `${apiUrl}/route`,
      {
        fromChain: request.fromChain,
        fromToken: request.fromToken,
        fromAmount: request.fromAmount,
        toChain: request.toChain,
        toToken: request.toToken,
        quoteOnly: request.quoteOnly,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-integrator-id": integratorId,
        },
        ...(options.signal ? { signal: options.signal } : {}),
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching route:", error.message);
    throw error;
  }
};

export const fetchAcross = async (params: FetchAcrossParams): Promise<SuggestedFeesResponse> => {
  try {
    const queryParams = new URLSearchParams({
      inputToken: params.inputToken,
      outputToken: params.outputToken,
      originChainId: params.originChainId.toString(),
      destinationChainId: params.destinationChainId.toString(),
      amount: params.amount,
      ...(params.recipient && { recipient: params.recipient }),
      ...(params.skipAmountLimit && { skipAmountLimit: params.skipAmountLimit.toString() }),
      depositMethod: "depositExclusive",
    });

    const url = `https://app.across.to/api/suggested-fees?${queryParams.toString()}`;

    const response = await axios.get<SuggestedFeesResponse>(url);

    return response.data;
  } catch (error) {
    console.error("Error fetching Across suggested fees:", error);
    throw error;
  }
};

export const fetchSynapse = async (params: FetchSynapseParams): Promise<SynapseQuote[]> => {
  try {
    const queryParams = new URLSearchParams({
      originChainID: params.originChainID.toString(),
      originTokenAddr: params.originTokenAddr,
      destChainID: params.destChainID.toString(),
      destTokenAddr: params.destTokenAddr,
      relayerAddr: params.relayerAddr,
    });

    const url = `https://api.synapseprotocol.com/quotes?${queryParams.toString()}`;

    const response = await axios.get<SynapseQuote[]>(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching Synapse quotes:", error);
    throw error;
  }
};

export const fetchRelay = async (params: FetchRelayParams): Promise<RelayQuote> => {
  try {
    const response = await axios.post<RelayQuote>("https://api.relay.link/quote", params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Relay quote:", error);
    throw error;
  }
};

export const fetchSymbiosis = async (params: FetchSymbiosisParams): Promise<SymbiosisQuote> => {
  try {
    const response = await axios.post<SymbiosisQuote>(
      "https://api.symbiosis.finance/crosschain/v1/swap",
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Symbiosis quote:", error);
    throw error;
  }
};

export const fetchRouterNitro = async (
  params: FetchRouterNitroParams,
): Promise<RouterNitroQuote> => {
  try {
    const queryParams = new URLSearchParams({
      amount: params.amount,
      fromTokenAddress: params.fromTokenAddress,
      fromTokenChainId: params.fromTokenChainId,
      toTokenAddress: params.toTokenAddress,
      toTokenChainId: params.toTokenChainId,
    });

    const url = `https://api-beta.pathfinder.routerprotocol.com/api/v2/quote?${queryParams.toString()}`;

    const response = await axios.get<RouterNitroQuote>(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching Router Nitro quotes:", error);
    throw error;
  }
};

export const fetchOrbiter = async (params: FetchOrbiterParams): Promise<OrbiterQuote> => {
  try {
    const queryParams = new URLSearchParams({
      line: params.line,
      value: params.value,
    });

    const url = `https://api.orbiter.finance/sdk/routers/simulation/receiveAmount?${queryParams.toString()}`;

    const response = await axios.get<OrbiterQuote>(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching Orbiter quote:", error);
    throw error;
  }
};

export const fetchDebridge = async (params: FetchDebridgeParams): Promise<DebridgeQuote> => {
  try {
    const queryParams = new URLSearchParams({
      srcChainId: params.srcChainId,
      srcChainTokenIn: params.srcChainTokenIn,
      srcChainTokenInAmount: params.srcChainTokenInAmount,
      dstChainId: params.dstChainId,
      dstChainTokenOut: params.dstChainTokenOut,
      dstChainTokenOutAmount: params.dstChainTokenOutAmount,
    });

    const url = `https://dln.debridge.finance/v1.0/dln/order/create-tx?${queryParams.toString()}`;

    const response = await axios.get<DebridgeQuote>(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching deBridge quote:", error);
    throw error;
  }
};

export const fetchStargate = async (
  params: FetchStargateParams,
  rpcUrl: string,
): Promise<StargateQuote> => {
  try {
    const provider = new ethers.JsonRpcProvider(rpcUrl);

    const contract = new ethers.Contract(params.target, stargateAbi, provider);

    const taxiParams = {
      sender: params.sender,
      dstEid: params.dstEid,
      receiver: ethers.ZeroHash,
      amountSD: params.amountSD,
      composeMsg: "0x",
      extraOptions: "0x",
    };

    const messagingFee = await contract.quoteTaxi(taxiParams, params.payInLzToken);

    return messagingFee;
  } catch (error) {
    console.error("Error fetching Stargate quote:", error);
    throw error;
  }
};
