import { ExtendedRFQEventData } from "../types";

export function computeAllStatistics(events: ExtendedRFQEventData[]) {
  const fees = {
    totalCollected: 0,
    pending: 0,
    lost: 0,
  };

  const userOrders = {
    total: 0,
    refunded: 0,
    completed: 0,
    perChain: {} as Record<string, number>,
    perTokenPerChain: {} as Record<string, Record<string, number>>,
  };

  const fillerOrders = {
    filled: 0,
    pendingHubBatching: {} as Record<string, number>,
    pendingSpokeBatching: {} as Record<string, number>,
    perChain: {} as Record<string, number>,
    perTokenPerChain: {} as Record<string, Record<string, number>>,
  };

  const userAmounts = {
    perTokenPerChain: {} as Record<string, Record<string, number>>,
    perChainValue: {} as Record<string, number>,
    perChainUsdValue: {} as Record<string, number>,
    perTokenPerChainUsdValue: {} as Record<string, Record<string, number>>,
  };

  const fillerAmounts = {
    perTokenPerChain: {} as Record<string, Record<string, number>>,
    perChainValue: {} as Record<string, number>,
    perChainUsdValue: {} as Record<string, number>,
    perTokenPerChainUsdValue: {} as Record<string, Record<string, number>>,
  };

  events.forEach(event => {
    const {
      TokensReleased,
      OrderRefunded,
      OrderFilled,
      SettlementForwarded,
      SettlementProcessed,
      SettlementFilled,
    } = event.statuses;
    const fromChain = event.orderCreated?.data.order_fromChain!;
    const toChain = event.orderCreated?.data.order_toChain!;
    const fromToken = event.orderCreated?.data.order_fromToken!;
    const toToken = event.orderCreated?.data.order_toToken!;
    const fromAmount = parseFloat(event.orderCreated?.data.order_fromAmount || "0");
    const toAmount = parseFloat(event.orderCreated?.data.order_fillAmount || "0");
    const usdValueFromToken = parseFloat(event.usdValueFromToken || "0");
    const usdValueToToken = parseFloat(event.usdValueToToken || "0");

    // Fees
    if (TokensReleased) {
      fees.totalCollected += parseFloat(event.feeValue || "0");
    } else {
      fees.pending += parseFloat(event.feeValue || "0");
    }
    if (OrderRefunded) {
      fees.lost += parseFloat(event.feeValue || "0");
    }

    // User
    userOrders.total++;
    if (OrderRefunded) userOrders.refunded++;
    if (TokensReleased) userOrders.completed++;
    userOrders.perChain[fromChain] = (userOrders.perChain[fromChain] || 0) + 1;
    userOrders.perTokenPerChain[fromChain] = userOrders.perTokenPerChain[fromChain] || {};
    userOrders.perTokenPerChain[fromChain][fromToken] =
      (userOrders.perTokenPerChain[fromChain][fromToken] || 0) + 1;

    // Filler
    if (OrderFilled) fillerOrders.filled++;
    if (OrderFilled && !SettlementForwarded) {
      fillerOrders.pendingHubBatching[toChain] =
        (fillerOrders.pendingHubBatching[toChain] || 0) + 1;
    }
    if (SettlementProcessed && !SettlementFilled) {
      fillerOrders.pendingSpokeBatching[fromChain] =
        (fillerOrders.pendingSpokeBatching[fromChain] || 0) + 1;
    }
    fillerOrders.perChain[toChain] = (fillerOrders.perChain[toChain] || 0) + 1;
    fillerOrders.perTokenPerChain[toChain] = fillerOrders.perTokenPerChain[toChain] || {};
    fillerOrders.perTokenPerChain[toChain][toToken] =
      (fillerOrders.perTokenPerChain[toChain][toToken] || 0) + 1;

    // User amounts
    userAmounts.perTokenPerChain[fromChain] = userAmounts.perTokenPerChain[fromChain] || {};
    userAmounts.perTokenPerChain[fromChain][fromToken] =
      (userAmounts.perTokenPerChain[fromChain][fromToken] || 0) + fromAmount;
    userAmounts.perChainValue[fromChain] = (userAmounts.perChainValue[fromChain] || 0) + fromAmount;
    userAmounts.perChainUsdValue[fromChain] =
      (userAmounts.perChainUsdValue[fromChain] || 0) + usdValueFromToken;
    userAmounts.perTokenPerChainUsdValue[fromChain] =
      userAmounts.perTokenPerChainUsdValue[fromChain] || {};
    userAmounts.perTokenPerChainUsdValue[fromChain][fromToken] =
      (userAmounts.perTokenPerChainUsdValue[fromChain][fromToken] || 0) + usdValueFromToken;

    // Filler amounts
    fillerAmounts.perTokenPerChain[toChain] = fillerAmounts.perTokenPerChain[toChain] || {};
    fillerAmounts.perTokenPerChain[toChain][toToken] =
      (fillerAmounts.perTokenPerChain[toChain][toToken] || 0) + toAmount;
    fillerAmounts.perChainValue[toChain] = (fillerAmounts.perChainValue[toChain] || 0) + toAmount;
    fillerAmounts.perChainUsdValue[toChain] =
      (fillerAmounts.perChainUsdValue[toChain] || 0) + usdValueToToken;
    fillerAmounts.perTokenPerChainUsdValue[toChain] =
      fillerAmounts.perTokenPerChainUsdValue[toChain] || {};
    fillerAmounts.perTokenPerChainUsdValue[toChain][toToken] =
      (fillerAmounts.perTokenPerChainUsdValue[toChain][toToken] || 0) + usdValueToToken;
  });

  return { fees, userOrders, fillerOrders, userAmounts, fillerAmounts };
}
