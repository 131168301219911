import React from "react";
import { Link } from "react-router-dom";
import { brandingContainerStyle, iconStyle } from "../styles/styles";

const Branding: React.FC = () => (
  <div style={brandingContainerStyle}>
    <Link to="/tx/" style={{ textDecoration: "none", color: "inherit" }}>
      <img
        src="https://raw.githubusercontent.com/0xsquid/assets/refs/heads/main/squid-brand-assets/icon/squid-icon-yellow.png"
        alt="Brand Icon"
        style={iconStyle}
      />
    </Link>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Link to="/tx/" style={{ textDecoration: "none", color: "inherit" }}>
        <span
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: "var(--text-color)",
            display: "flex",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          CORALSCAN
        </span>
      </Link>
      <Link to="/tx/" style={{ textDecoration: "none", color: "inherit" }}>
        <span
          style={{
            fontSize: "0.75rem",
            fontWeight: "bold",
            color: "#d5bef4",
          }}
        >
          BETA
        </span>
      </Link>
    </div>
  </div>
);

export default Branding;
