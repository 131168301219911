import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  chains,
  currentTime,
  getBlockExplorerUrl,
  getChainImage,
  getTokenImage,
  stepLabels,
} from "../utils/constants";
import {
  txContainerStyle,
  lineWrapperStyle,
  settlementLineWrapperStyle,
  lineStyle,
  circleStyle,
  circleContainerStyle,
  sourceTokenCircleContainerStyle,
  destinationTokenCircleContainerStyle,
  txTextLinkStyle,
  settlementTxTextLinkStyle,
  pendingTextStyle,
  settlementPendingTextStyle,
  txHeaderStyle,
  detailsContainerStyle,
  centerTextStyle,
  timeTextContainerStyle,
  imageStyle,
  orderDataContainerStyle,
  orderDataItemStyle,
  orderDataLabelStyle,
  orderDataValueStyle,
  notificationStyle,
  showNotificationStyle,
  spinnerStyle,
  headerContainerStyle,
  iconStyle,
  searchIconStyle,
  searchInputContainerStyle,
  copyIconStyle,
  nightToggleStyle,
  spinnerContainerStyle,
  txSummaryLinkStyle,
  fullHeightStyle,
  noOrdersFoundStyle,
} from "../styles/styles";
import {
  calculateElapsedTime,
  calculateFeeTotal,
  calculateFinalExchangeRate,
  calculateRawExchangeRate,
  calculateSourceTotal,
  formatDate,
  formatElapsedTime,
  formatAddress,
  formatTokenAmount,
  formatTxHash,
} from "../utils/formatting";
import { SquidRFQOrder } from "../types";
import { fetchOrder } from "../services/api";
import { IconCopy, IconMoon, IconMoonFilled, IconSearch } from "@tabler/icons-react";
import { isValidAddress, isValidTransactionHash } from "../utils/validation";
import Tooltip from "./Tooltip";
import TopBanner from "./TopBanner";
import Branding from "./Branding";

export const Spinner = () => (
  <div style={spinnerContainerStyle}>
    <div style={spinnerStyle}></div>
  </div>
);

const Notification = ({ message, visible }: { visible: boolean; message: string }) => (
  <div
    style={{
      ...notificationStyle,
      ...(visible ? showNotificationStyle : {}),
    }}
  >
    {message}
  </div>
);

const TransactionDetails: React.FC = () => {
  const { transactionHash } = useParams<{ transactionHash: string }>();
  const navigate = useNavigate();
  const [hashInput, setHashInput] = useState("");
  const [orderData, setOrderData] = useState<SquidRFQOrder | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isNightMode, setIsNightMode] = useState<boolean>(() => {
    const localStorageValue = localStorage.getItem("isNightMode");
    return localStorageValue !== null ? localStorageValue === "true" : true;
  });

  const toggleNightMode = () => {
    setIsNightMode(!isNightMode);
    localStorage.setItem("isNightMode", (!isNightMode).toString());
  };

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--bg-color", isNightMode ? "#1b212b" : "#fff");
    root.style.setProperty("--text-color", isNightMode ? "#fff" : "#333");
    root.style.setProperty("--text2-color", isNightMode ? "#333" : "#fff");
    root.style.setProperty("--time-color", isNightMode ? "#fff" : "#555");
    root.style.setProperty("--search-color", isNightMode ? "#666" : "#888");
    root.style.setProperty("--link-color", isNightMode ? "lightBlue" : "blue");
    root.style.setProperty("--accent-color", isNightMode ? "#e3f734" : "#d5bef4");
    root.style.setProperty("--accent2-color", isNightMode ? "#d5bef4" : "#e3f734");
    root.style.setProperty("--shadow-color", isNightMode ? "#13171f" : "#f9f9f9");

    if (localStorage.getItem("isNightMode") === null) {
      localStorage.setItem("isNightMode", "true");
    }
  }, [isNightMode]);

  useEffect(() => {
    const loadOrderData = async (hash: string) => {
      setLoading(true);
      try {
        const data = await fetchOrder(hash);
        setOrderData(data);
        setError(null);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError("Order not found");
        } else {
          setError("Failed to fetch order data.");
        }
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (transactionHash) {
      loadOrderData(transactionHash);
    }
  }, [transactionHash]);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidTransactionHash(hashInput)) {
      navigate(`/tx/${hashInput}`);
    } else if (isValidAddress(hashInput)) {
      navigate(`/latest/${hashInput}`);
    } else {
      setNotificationMessage("Invalid transaction hash or address");
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
      inputRef.current?.focus();
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement !== inputRef.current) {
        setIsFocused(false);
      }
    }, 100);
  };

  const elapsedTime = orderData
    ? orderData.orderFilledBlockTimestamp
      ? orderData.orderFilledBlockTimestamp - orderData.orderCreatedBlockTimestamp
      : currentTime - orderData.orderCreatedBlockTimestamp
    : null;

  const formattedElapsedTime = elapsedTime !== null ? formatElapsedTime(elapsedTime) : "N/A";

  const settlementTimestamps = [
    orderData?.settlementForwardedBlockTimestamp,
    orderData?.settlementProcessedBlockTimestamp,
    orderData?.settlementFilledBlockTimestamp,
    orderData?.tokensReleasedBlockTimestamp,
  ];

  const transactionHashes = [
    orderData?.settlementForwardedTxHash,
    orderData?.settlementProcessedTxHash,
    orderData?.settlementFilledTxHash,
    orderData?.tokensReleasedTxHash,
  ];

  const settlementChainKeys = [
    orderData?.toChain, // Settlement Forwarded
    "250", // Settlement Processed
    "250", // Settlement Filled
    orderData?.fromChain, // Tokens Released
  ];

  const settlementChainIds = [
    orderData?.toChain, // Settlement Forwarded
    "250", // Settlement Processed
    "250", // Settlement Filled
    orderData?.fromChain, // Tokens Released
  ];

  const settlementChainNames = settlementChainKeys.map(key => chains[key as string] || "unknown");

  const swapChainKeys = [orderData?.fromChain, orderData?.toChain];
  const swapChainNames = swapChainKeys.map(key => chains[key as string] || "unknown");

  const swapTokenSymbols = [
    orderData?.fromTokenSymbol.toLowerCase() || "",
    orderData?.toTokenSymbol.toLowerCase() || "",
  ];

  const isCompleted = Boolean(orderData?.orderFilledTxHash);

  const copyToClipboard = (text: string, transactionType: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setNotificationMessage(`${transactionType} copied to clipboard`);
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
    });
  };

  const feeRate = orderData?.feeRate ?? "0";

  const rawExchangeRate =
    orderData && calculateRawExchangeRate(orderData.fromAmount!, orderData.fillAmount!);
  const finalExchangeRate =
    orderData && calculateFinalExchangeRate(orderData.fromAmount!, orderData.fillAmount!, feeRate);
  const sourceTotal = orderData && calculateSourceTotal(orderData.fromAmount!, feeRate).toString();
  const feeTotal = orderData && calculateFeeTotal(orderData.fromAmount!, feeRate);

  return (
    <div style={fullHeightStyle}>
      <TopBanner />
      <div style={txContainerStyle}>
        <div style={nightToggleStyle}>
          {isNightMode ? (
            <IconMoonFilled
              width={16}
              height={16}
              style={{ color: "#e3f734", cursor: "pointer", ...iconStyle }}
              onClick={toggleNightMode}
            />
          ) : (
            <IconMoon
              width={16}
              height={16}
              style={{ color: "#d5bef4", cursor: "pointer", ...iconStyle }}
              onClick={toggleNightMode}
            />
          )}
        </div>
        <div style={headerContainerStyle}>
          <Branding />
          <form onSubmit={handleSearchSubmit} style={searchInputContainerStyle}>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                onChange={e => setHashInput(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                style={{
                  width: isFocused ? "600px" : "300px",
                  transition: "width 0.3s ease-in-out, left 0.3s ease-in-out",
                  padding: "10px 40px 10px 10px",
                  borderRadius: "8px",
                  border: `1px solid ${isFocused ? "var(--accent-color)" : "var(--text2-color)"}`,
                  backgroundColor: "var(--bg-color)",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                  color: "var(--text-color)",
                  fontSize: "16px",
                  position: "absolute",
                  right: "0",
                }}
                placeholder="Search by tx hash, order hash, or address"
                value={hashInput}
              />
              {hashInput && (
                <IconSearch
                  style={{
                    ...searchIconStyle,
                    color: "var(--search-color)",
                    cursor: "pointer",
                    position: "absolute",
                    right: 10,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={handleSearchSubmit}
                />
              )}
            </div>
          </form>
        </div>

        {loading ? (
          <Spinner />
        ) : error === "Order not found" ? (
          <div style={noOrdersFoundStyle}>Order not found</div>
        ) : (
          <>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Notification message={notificationMessage} visible={notificationVisible} />

            {/* Swap Status */}
            <h2 style={txHeaderStyle}>Swap Status</h2>
            {orderData ? (
              <div>
                <div style={lineWrapperStyle}>
                  <div style={sourceTokenCircleContainerStyle}>
                    <div style={circleStyle(true)}>
                      <img
                        src={getChainImage(swapChainNames[0])}
                        alt="Left Chain"
                        style={imageStyle}
                      />
                    </div>
                  </div>

                  <div style={circleContainerStyle}>
                    <div style={circleStyle(!!orderData?.orderCreatedTxHash)}>
                      <img
                        src={getTokenImage(swapTokenSymbols[0])}
                        alt="Created"
                        style={imageStyle}
                      />
                    </div>
                    {orderData?.orderCreatedTxHash ? (
                      <a
                        href={getBlockExplorerUrl(
                          orderData.fromChain!,
                          orderData.orderCreatedTxHash,
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={txTextLinkStyle}
                      >
                        Created
                      </a>
                    ) : (
                      <span style={pendingTextStyle}>Pending Created</span>
                    )}
                  </div>

                  <div
                    style={lineStyle(
                      Boolean(orderData?.orderFilledTxHash) ||
                        Boolean(orderData?.orderRefundedTxHash),
                    )}
                  />

                  {orderData?.orderRefundedTxHash && (
                    <div style={circleContainerStyle}>
                      <div style={circleStyle(true)}>
                        <img
                          src={getTokenImage(swapTokenSymbols[0])}
                          alt="Refunded"
                          style={imageStyle}
                        />
                      </div>
                      <a
                        href={getBlockExplorerUrl(
                          orderData.fromChain!,
                          orderData.orderRefundedTxHash,
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={txTextLinkStyle}
                      >
                        Refunded
                      </a>
                    </div>
                  )}

                  <div style={lineStyle(isCompleted)} />

                  <div style={circleContainerStyle}>
                    <div style={circleStyle(!!orderData?.orderFilledTxHash)}>
                      <img
                        src={getTokenImage(swapTokenSymbols[1])}
                        alt="Filled"
                        style={imageStyle}
                      />
                    </div>
                    {orderData?.orderFilledTxHash ? (
                      <a
                        href={getBlockExplorerUrl(orderData.toChain!, orderData.orderFilledTxHash)}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={txTextLinkStyle}
                      >
                        Filled
                      </a>
                    ) : orderData?.orderRefundedTxHash ? null : (
                      <span style={pendingTextStyle}>Pending Fill</span>
                    )}
                  </div>

                  <div style={destinationTokenCircleContainerStyle}>
                    <div style={circleStyle(true)}>
                      <img
                        src={getChainImage(swapChainNames[1])}
                        alt="Right Chain"
                        style={imageStyle}
                      />
                    </div>
                  </div>
                </div>

                <div style={centerTextStyle}>
                  {orderData?.orderFilledBlockTimestamp
                    ? `Filled in ${formattedElapsedTime}`
                    : orderData?.orderRefundedTxHash
                      ? ""
                      : `Filling... ${formattedElapsedTime}`}
                </div>

                {/* Settlement Status */}
                {!orderData?.orderRefundedTxHash && (
                  <>
                    <h2 style={txHeaderStyle}>Settlement Status</h2>
                    <div style={settlementLineWrapperStyle}>
                      {settlementTimestamps.map((timestamp, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && (
                            <div
                              style={lineStyle(!!settlementTimestamps[index - 1] && !!timestamp)}
                            />
                          )}
                          <div style={circleContainerStyle}>
                            <div style={circleStyle(!!timestamp)}>
                              <img
                                src={getChainImage(settlementChainNames[index])}
                                alt={stepLabels[index]}
                                style={imageStyle}
                              />
                            </div>
                            {timestamp ? (
                              transactionHashes[index] ? (
                                <a
                                  href={getBlockExplorerUrl(
                                    settlementChainIds[index]!,
                                    transactionHashes[index]!,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={settlementTxTextLinkStyle}
                                >
                                  {stepLabels[index]}
                                </a>
                              ) : (
                                <span style={settlementPendingTextStyle}>
                                  Pending {stepLabels[index]}
                                </span>
                              )
                            ) : (
                              <span style={settlementPendingTextStyle}>
                                Pending {stepLabels[index]}
                              </span>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>

                    <div style={timeTextContainerStyle}>
                      {settlementTimestamps.map((startTimestamp, index) => {
                        const endTimestamp = settlementTimestamps[index + 1];
                        if (index < settlementTimestamps.length - 1) {
                          return (
                            <div key={index} style={{ textAlign: "center", flex: 1 }}>
                              {calculateElapsedTime(startTimestamp, endTimestamp)}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </>
                )}

                {/* Order Data */}
                <div style={detailsContainerStyle}>
                  <div style={orderDataContainerStyle}>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Source Chain</div>
                      <div style={orderDataValueStyle}>
                        <img
                          src={getChainImage(chains[orderData.fromChain || ""])}
                          alt="Source Chain"
                          style={{ ...imageStyle, width: 24, height: 24 }}
                        />
                        {chains[orderData.fromChain || "N/A"].charAt(0).toUpperCase() +
                          chains[orderData.fromChain || "unknown"].slice(1)}
                        <span style={{ marginLeft: "8px" }}>{orderData.fromChain || ""}</span>
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Destination Chain</div>
                      <div style={orderDataValueStyle}>
                        <img
                          src={getChainImage(chains[orderData.toChain || ""])}
                          alt="Destination Chain"
                          style={{ ...imageStyle, width: 24, height: 24 }}
                        />
                        {chains[orderData.toChain || "N/A"].charAt(0).toUpperCase() +
                          chains[orderData.toChain || "unknown"].slice(1)}
                        <span style={{ marginLeft: "8px" }}>{orderData.toChain || ""}</span>
                      </div>
                    </div>

                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Source Address</div>
                      <div style={orderDataValueStyle}>
                        <img
                          src={getChainImage(chains[orderData.fromChain || ""])}
                          alt="Source Address"
                          style={{ ...imageStyle, width: 24, height: 24 }}
                        />
                        <span style={{ marginLeft: "8px" }}>
                          {formatAddress(orderData.fromAddress || "")}
                        </span>
                        <IconCopy
                          width={16}
                          height={16}
                          style={{ ...copyIconStyle, cursor: "pointer" }}
                          onClick={() => copyToClipboard(orderData.fromAddress!, "From Address")}
                        />
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Destination Address</div>
                      <div style={orderDataValueStyle}>
                        <img
                          src={getChainImage(chains[orderData.toChain || ""])}
                          alt="Destination Address"
                          style={{ ...imageStyle, width: 24, height: 24 }}
                        />
                        <span style={{ marginLeft: "8px" }}>
                          {formatAddress(orderData.toAddress || "")}
                        </span>
                        <IconCopy
                          width={16}
                          height={16}
                          style={{ ...copyIconStyle, cursor: "pointer" }}
                          onClick={() => copyToClipboard(orderData.toAddress!, "To Address")}
                        />
                      </div>
                    </div>

                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Source Asset</div>
                      <div style={orderDataValueStyle}>
                        <img
                          src={getTokenImage(orderData.fromTokenSymbol?.toLowerCase() || "")}
                          alt="Source Asset"
                          style={{ ...imageStyle, width: 24, height: 24 }}
                        />
                        {formatTokenAmount(
                          orderData.fromAmount || "0",
                          (orderData.fromTokenDecimals !== undefined
                            ? orderData.fromTokenDecimals
                            : orderData.fromTokenSymbol === "USDC"
                              ? "6"
                              : "18"
                          ).toString(),
                        )}{" "}
                        {orderData.fromTokenSymbol}
                        <span style={{ marginLeft: "8px" }}>
                          {formatAddress(orderData.fromToken || "")}
                        </span>
                        <IconCopy
                          width={16}
                          height={16}
                          style={{ ...copyIconStyle, cursor: "pointer" }}
                          onClick={() =>
                            copyToClipboard(orderData.fromToken!, "From Token Address")
                          }
                        />
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Destination Asset</div>
                      <div style={orderDataValueStyle}>
                        <img
                          src={getTokenImage(orderData.toTokenSymbol?.toLowerCase() || "")}
                          alt="Destination Asset"
                          style={{ ...imageStyle, width: 24, height: 24 }}
                        />
                        {formatTokenAmount(
                          orderData.fillAmount || "0",
                          (orderData.toTokenDecimals !== undefined
                            ? orderData.toTokenDecimals
                            : orderData.toTokenSymbol === "USDC"
                              ? "6"
                              : "18"
                          ).toString(),
                        )}{" "}
                        {orderData.toTokenSymbol}
                        <span style={{ marginLeft: "8px" }}>
                          {formatAddress(orderData.toToken || "")}
                        </span>
                        <IconCopy
                          width={16}
                          height={16}
                          style={{ ...copyIconStyle, cursor: "pointer" }}
                          onClick={() => copyToClipboard(orderData.toToken!, "To Token Address")}
                        />
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Fee Rate</div>
                      <div style={orderDataValueStyle}>{orderData.feeRate}</div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <Tooltip
                        text={
                          <>
                            <strong>Raw Exchange Rate:</strong>
                            <br />
                            fillAmount / fromAmount
                          </>
                        }
                      >
                        <div style={orderDataLabelStyle}>Raw Exchange Rate</div>
                      </Tooltip>
                      <div style={orderDataValueStyle}>{rawExchangeRate.toFixed(6)}</div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <Tooltip
                        text={
                          <>
                            <strong>Final Exchange Rate:</strong>
                            <br />
                            fillAmount / (fromAmount - ((fromAmount * feeRate / 1000000)))
                          </>
                        }
                      >
                        <div style={orderDataLabelStyle}>Final Exchange Rate</div>
                      </Tooltip>
                      <div style={orderDataValueStyle}>{finalExchangeRate.toFixed(6)}</div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <Tooltip
                        text={
                          <>
                            <strong>Source Total:</strong>
                            <br />
                            fromAmount - ((fromAmount * feeRate) / 1000000)
                          </>
                        }
                      >
                        <div style={orderDataLabelStyle}>Source Total</div>
                      </Tooltip>
                      <div style={orderDataValueStyle}>
                        <img
                          src={getTokenImage(orderData.fromTokenSymbol?.toLowerCase() || "")}
                          alt="Source Total"
                          style={{ ...imageStyle, width: 24, height: 24 }}
                        />
                        {formatTokenAmount(
                          sourceTotal || "0",
                          (orderData.fromTokenDecimals !== undefined
                            ? orderData.fromTokenDecimals
                            : orderData.fromTokenSymbol === "USDC"
                              ? "6"
                              : "18"
                          ).toString(),
                        )}{" "}
                        {orderData.fromTokenSymbol}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <Tooltip
                        text={
                          <>
                            <strong>Fee Total:</strong>
                            <br />
                            (fromAmount * feeRate) / 1000000
                          </>
                        }
                      >
                        <div style={orderDataLabelStyle}>Fee Total</div>
                      </Tooltip>
                      <div style={orderDataValueStyle}>
                        <img
                          src={getTokenImage(orderData.fromTokenSymbol?.toLowerCase() || "")}
                          alt="Fee Total"
                          style={{ ...imageStyle, width: 24, height: 24 }}
                        />
                        {formatTokenAmount(
                          feeTotal || "0",
                          (orderData.fromTokenDecimals !== undefined
                            ? orderData.fromTokenDecimals
                            : orderData.fromTokenSymbol === "USDC"
                              ? "6"
                              : "18"
                          ).toString(),
                        )}{" "}
                        {orderData.fromTokenSymbol}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Source Amount USD</div>
                      <div style={orderDataValueStyle}>
                        $
                        {parseFloat(orderData.fromAmountUSD).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Destination Amount USD</div>
                      <div style={orderDataValueStyle}>
                        $
                        {parseFloat(orderData.fillAmountUSD).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Created</div>
                      <div style={orderDataValueStyle}>
                        {formatDate(orderData.orderCreatedBlockTimestamp)}
                        {orderData.orderCreatedTxHash && (
                          <>
                            <span style={{ marginLeft: "8px" }}>
                              {formatTxHash(orderData.orderCreatedTxHash)}
                            </span>
                            <IconCopy
                              width={16}
                              height={16}
                              style={{ ...copyIconStyle, cursor: "pointer" }}
                              onClick={() =>
                                copyToClipboard(
                                  orderData.orderCreatedTxHash!,
                                  "Order Created transaction hash",
                                )
                              }
                            />
                            <span style={{ marginLeft: "8px" }}>
                              <a
                                href={getBlockExplorerUrl(
                                  orderData.fromChain!,
                                  orderData.orderCreatedTxHash,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={txSummaryLinkStyle}
                              >
                                View on Block Explorer
                              </a>
                            </span>
                            <img
                              src={getChainImage(chains[orderData.fromChain || ""])}
                              alt="Source Chain"
                              style={{ ...imageStyle, width: 24, height: 24 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Refunded</div>
                      <div style={orderDataValueStyle}>
                        {formatDate(orderData.orderRefundedBlockTimestamp)}
                        {orderData.orderRefundedTxHash && (
                          <>
                            <span style={{ marginLeft: "8px" }}>
                              {formatTxHash(orderData.orderRefundedTxHash)}
                            </span>
                            <IconCopy
                              width={16}
                              height={16}
                              style={{ ...copyIconStyle, cursor: "pointer" }}
                              onClick={() =>
                                copyToClipboard(
                                  orderData.orderRefundedTxHash!,
                                  "Order Refunded transaction hash",
                                )
                              }
                            />
                            <span style={{ marginLeft: "8px" }}>
                              <a
                                href={getBlockExplorerUrl(
                                  orderData.fromChain!,
                                  orderData.orderRefundedTxHash,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={txSummaryLinkStyle}
                              >
                                View on Block Explorer
                              </a>
                            </span>
                            <img
                              src={getChainImage(chains[orderData.fromChain || ""])}
                              alt="Source Chain"
                              style={{ ...imageStyle, width: 24, height: 24 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Filled</div>
                      <div style={orderDataValueStyle}>
                        {formatDate(orderData.orderFilledBlockTimestamp)}
                        {orderData.orderFilledTxHash && (
                          <>
                            <span style={{ marginLeft: "8px" }}>
                              {formatTxHash(orderData.orderFilledTxHash)}
                            </span>
                            <IconCopy
                              width={16}
                              height={16}
                              style={{ ...copyIconStyle, cursor: "pointer" }}
                              onClick={() =>
                                copyToClipboard(
                                  orderData.orderFilledTxHash!,
                                  "Order Filled transaction hash",
                                )
                              }
                            />
                            <span style={{ marginLeft: "8px" }}>
                              <a
                                href={getBlockExplorerUrl(
                                  orderData.toChain!,
                                  orderData.orderFilledTxHash,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={txSummaryLinkStyle}
                              >
                                View on Block Explorer
                              </a>
                            </span>
                            <img
                              src={getChainImage(chains[orderData.toChain || ""])}
                              alt="Destination Chain"
                              style={{ ...imageStyle, width: 24, height: 24 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Settlement Forwarded</div>
                      <div style={orderDataValueStyle}>
                        {formatDate(orderData.settlementForwardedBlockTimestamp)}
                        {orderData.settlementForwardedTxHash && (
                          <>
                            <span style={{ marginLeft: "8px" }}>
                              {formatTxHash(orderData.settlementForwardedTxHash)}
                            </span>
                            <IconCopy
                              width={16}
                              height={16}
                              style={{ ...copyIconStyle, cursor: "pointer" }}
                              onClick={() =>
                                copyToClipboard(
                                  orderData.settlementForwardedTxHash!,
                                  "Settlement Forwarded transaction hash",
                                )
                              }
                            />
                            <span style={{ marginLeft: "8px" }}>
                              <a
                                href={getBlockExplorerUrl(
                                  orderData.toChain!,
                                  orderData.settlementForwardedTxHash,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={txSummaryLinkStyle}
                              >
                                View on Block Explorer
                              </a>
                            </span>
                            <img
                              src={getChainImage(chains[orderData.toChain || ""])}
                              alt="Destination Chain"
                              style={{ ...imageStyle, width: 24, height: 24 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Settlement Processed</div>
                      <div style={orderDataValueStyle}>
                        {formatDate(orderData.settlementProcessedBlockTimestamp)}
                        {orderData.settlementProcessedTxHash && (
                          <>
                            <span style={{ marginLeft: "8px" }}>
                              {formatTxHash(orderData.settlementProcessedTxHash)}
                            </span>
                            <IconCopy
                              width={16}
                              height={16}
                              style={{ ...copyIconStyle, cursor: "pointer" }}
                              onClick={() =>
                                copyToClipboard(
                                  orderData.settlementProcessedTxHash!,
                                  "Settlement Processed transaction hash",
                                )
                              }
                            />
                            <span style={{ marginLeft: "8px" }}>
                              <a
                                href={getBlockExplorerUrl(
                                  "250",
                                  orderData.settlementProcessedTxHash,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={txSummaryLinkStyle}
                              >
                                View on Block Explorer
                              </a>
                            </span>
                            <img
                              src={getChainImage(chains["250"])}
                              alt="Hub Chain"
                              style={{ ...imageStyle, width: 24, height: 24 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Settlement Filled</div>
                      <div style={orderDataValueStyle}>
                        {formatDate(orderData.settlementFilledBlockTimestamp)}
                        {orderData.settlementFilledTxHash && (
                          <>
                            <span style={{ marginLeft: "8px" }}>
                              {formatTxHash(orderData.settlementFilledTxHash)}
                            </span>
                            <IconCopy
                              width={16}
                              height={16}
                              style={{ ...copyIconStyle, cursor: "pointer" }}
                              onClick={() =>
                                copyToClipboard(
                                  orderData.settlementFilledTxHash!,
                                  "Settlement Filled transaction hash",
                                )
                              }
                            />
                            <span style={{ marginLeft: "8px" }}>
                              <a
                                href={getBlockExplorerUrl("250", orderData.settlementFilledTxHash)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={txSummaryLinkStyle}
                              >
                                View on Block Explorer
                              </a>
                            </span>
                            <img
                              src={getChainImage(chains["250"])}
                              alt="Hub Chain"
                              style={{ ...imageStyle, width: 24, height: 24 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div style={orderDataItemStyle}>
                      <div style={orderDataLabelStyle}>Tokens Released</div>
                      <div style={orderDataValueStyle}>
                        {formatDate(orderData.tokensReleasedBlockTimestamp)}
                        {orderData.tokensReleasedTxHash && (
                          <>
                            <span style={{ marginLeft: "8px" }}>
                              {formatTxHash(orderData.tokensReleasedTxHash)}
                            </span>
                            <IconCopy
                              width={16}
                              height={16}
                              style={{ ...copyIconStyle, cursor: "pointer" }}
                              onClick={() =>
                                copyToClipboard(
                                  orderData.tokensReleasedTxHash!,
                                  "Tokens Released transaction hash",
                                )
                              }
                            />
                            <span style={{ marginLeft: "8px" }}>
                              <a
                                href={getBlockExplorerUrl(
                                  orderData.fromChain!,
                                  orderData.tokensReleasedTxHash,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={txSummaryLinkStyle}
                              >
                                View on Block Explorer
                              </a>
                            </span>
                            <img
                              src={getChainImage(chains[orderData.fromChain || ""])}
                              alt="Source Chain"
                              style={{ ...imageStyle, width: 24, height: 24 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionDetails;
