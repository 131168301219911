import { StargateChainConfig, RouteRequest } from "../types";

export const hubChainId = "250";
export const hubAddress = "0xccccc988a6234bf96c48bb2651bb1e67a8cba4c8";
export const spokeAddress = "0xddddd043bd7a886a26c1231e4305582fab219667";

export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;

export const chainConfigs: Record<string, StargateChainConfig> = {
  "1": {
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`, // Ethereum
    address: "0x6d6620eFa72948C5f68A3C8646d58C00d3f4A980",
    eid: "30101",
  },
  "10": {
    rpcUrl: `https://optimism-mainnet.infura.io/v3/${INFURA_KEY}`, // Optimism
    address: "0xF1fCb4CBd57B67d683972A59B6a7b1e2E8Bf27E6",
    eid: "30111",
  },
  "56": {
    rpcUrl: "https://bsc-dataseed.binance.org", // Binance
    address: "0x6E3d884C96d640526F273C61dfcF08915eBd7e2B",
    eid: "30102",
  },
  "137": {
    rpcUrl: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`, // Polygon
    address: "0x6CE9bf8CDaB780416AD1fd87b318A077D2f50EaC",
    eid: "30109",
  },
  // "250": {
  //   rpcUrl: "", // Fantom
  //   address: "",
  //   eid: "",
  // },
  // "252": {
  //   rpcUrl: "", // Fraxtal
  //   address: "",
  //   eid: "",
  // },
  // "314": {
  //   rpcUrl: "", // Filecoin
  //   address: "",
  //   eid: "",
  // },
  // "1284": {
  //   rpcUrl: "", // Moonbeam
  //   address: "",
  //   eid: "",
  // },
  // "2222": {
  //   rpcUrl: "", // Kava
  //   address: "0x6B73D3cBbb278Ce2E8698E983AecCdD94Dc4594B",
  //   eid: "30177",
  // },
  // "5000": {
  //   rpcUrl: "", // Mantle
  //   address: "0x41B491285A4f888F9f636cEc8a363AB9770a0AEF",
  //   eid: "30181",
  // },
  "8453": {
    rpcUrl: "https://base.llamarpc.com", // Base
    address: "0x5634c4a5FEd09819E3c46D86A965Dd9447d86e47",
    eid: "30184",
  },
  // "13371": {
  //   rpcUrl: "", // Immutable
  //   address: "",
  //   eid: "",
  // },
  "42161": {
    rpcUrl: `https://arbitrum-mainnet.infura.io/v3/${INFURA_KEY}`, // Arbitrum
    address: "0x19cFCE47eD54a88614648DC3f19A5980097007dD",
    eid: "30110",
  },
  // "42220": {
  //   rpcUrl: "", // Celo
  //   address: "",
  //   eid: "",
  // },
  "43114": {
    rpcUrl: `https://avalanche-mainnet.infura.io/v3/${INFURA_KEY}`, // Avalanche
    address: "0x17E450Be3Ba9557F2378E20d64AD417E59Ef9A34",
    eid: "30106",
  },
  "59144": {
    rpcUrl: `https://linea-mainnet.infura.io/v3/${INFURA_KEY}`, // Linea
    address: "0x5f688F563Dc16590e570f97b542FA87931AF2feD",
    eid: "30183",
  },
  // "81457": {
  //   rpcUrl: "", // Blast
  //   address: "",
  //   eid: "",
  // },
  // "534352": {
  //   rpcUrl: "", // Scroll
  //   address: "0x4e422B0aCb2Bd7e3aC70B5c0E5eb806e86a94038",
  //   eid: "30214",
  // },
};

export enum ApiUrls {
  SQUID_PROD = "https://apiplus.squidrouter.com/v2/route",
  SQUID_UAT = "https://api.uatsquidrouter.com/v2",
  SQUID_DEV = "https://api.devsquidrouter.com/v2",
  SQUID_LOCAL = "http://localhost:3000/v2",
  ACROSS_PROTOCOL = "https://app.across.to/api/suggested-fees?",
  SYNAPSE_PROTOCOL = "https://docs.synapseprotocol.com/quotes?",
  RELAY_PROTOCOL = "https://api.relay.link/quote",
  SYMBIOSIS_FINANCE = "https://api.symbiosis.finance/crosschain/v1/swap",
  ROUTER_NITRO = "https://api-beta.pathfinder.routerprotocol.com/api/v2/quote",
  ORBITER_FINANCE = "https://api.orbiter.finance/sdk/routers/simulation/receiveAmount",
  DEBRIDGE_FINANCE = "https://dln.debridge.finance/v1.0/dln/order/create-tx",
  STARGATE_FINANCE = "",
  EXODUS_API = "",
  COINBASE_API = "",
  BINANCE_API = "",
  LIFI_API = "",
}

export const Environments: Record<string, string> = {
  "squid-prod": ApiUrls.SQUID_PROD,
  "squid-uat": ApiUrls.SQUID_UAT,
  "squid-dev": ApiUrls.SQUID_DEV,
  "squid-local": ApiUrls.SQUID_LOCAL,
  "across-protocol": ApiUrls.ACROSS_PROTOCOL,
  "synapse-protocol": ApiUrls.SYNAPSE_PROTOCOL,
  "relay-protocol": ApiUrls.RELAY_PROTOCOL,
  "symbiosis-finance": ApiUrls.SYMBIOSIS_FINANCE,
  "router-nitro": ApiUrls.ROUTER_NITRO,
  "orbiter-finance": ApiUrls.ORBITER_FINANCE,
  "debridge-finance": ApiUrls.DEBRIDGE_FINANCE,
  "stargate-finance": ApiUrls.DEBRIDGE_FINANCE,
  "exodus-api": ApiUrls.EXODUS_API,
  "coinbase-api": ApiUrls.COINBASE_API,
  "binance-api": ApiUrls.BINANCE_API,
  "lifi-api": ApiUrls.LIFI_API,
};

export const EnvironmentMetadata: Record<
  string,
  { name: string; image: string; enabled: boolean }
> = {
  "squid-prod": {
    name: "Squid",
    image:
      "https://raw.githubusercontent.com/0xsquid/assets/refs/heads/main/squid-brand-assets/icon/squid-icon-yellow.png",
    enabled: false,
  },
  "squid-uat": {
    name: "Squid UAT",
    image:
      "https://raw.githubusercontent.com/0xsquid/assets/refs/heads/main/squid-brand-assets/icon/squid-icon-yellow.png",
    enabled: true,
  },
  "squid-dev": {
    name: "Squid Dev",
    image:
      "https://raw.githubusercontent.com/0xsquid/assets/refs/heads/main/squid-brand-assets/icon/squid-icon-yellow.png",
    enabled: true,
  },
  "squid-local": {
    name: "Squid Local",
    image:
      "https://raw.githubusercontent.com/0xsquid/assets/refs/heads/main/squid-brand-assets/icon/squid-icon-yellow.png",
    enabled: true,
  },
  "across-protocol": {
    name: "Across",
    image:
      "https://raw.githubusercontent.com/across-protocol/frontend/74a687cff46be5aad6f4a3d4fc65bef6dee130b8/public/favicon.svg",
    enabled: true,
  },
  "synapse-protocol": {
    name: "Synapse",
    image: "https://docs.synapseprotocol.com/brand-assets/synapse-mark.svg",
    enabled: true,
  },
  "relay-protocol": {
    name: "Relay",
    image: "relay.svg",
    enabled: true,
  },
  "symbiosis-finance": {
    name: "Symbiosis",
    image: "symbiosis.svg",
    enabled: true,
  },
  "router-nitro": {
    name: "Router Nitro",
    image: "router_nitro.svg",
    enabled: true,
  },
  "orbiter-finance": {
    name: "Orbiter",
    image: "orbiter3.svg",
    enabled: true,
  },
  "debridge-finance": {
    name: "deBridge",
    image: "debridge.svg",
    enabled: true,
  },
  "stargate-finance": {
    name: "Stargate",
    image: "stargate.svg",
    enabled: false,
  },
  "exodus-api": {
    name: "Exodus",
    image: "exodus.svg",
    enabled: false,
  },
  "coinbase-api": {
    name: "Coinbase",
    image: "coinbase.svg",
    enabled: false,
  },
  "binance-api": {
    name: "Binance",
    image: "binance.svg",
    enabled: false,
  },
  "lifi-api": {
    name: "LI.FI",
    image: "lifi.svg",
    enabled: false,
  },
};

export const EnvironmentIntegratorIds: Record<string, string> = {
  "squid-local": process.env.REACT_APP_BENCHMARK_INTEGRATOR_LOCAL || "",
  "squid-dev": process.env.REACT_APP_BENCHMARK_INTEGRATOR_DEV || "",
  "squid-uat": process.env.REACT_APP_BENCHMARK_INTEGRATOR_UAT || "",
  "squid-prod": process.env.REACT_APP_BENCHMARK_INTEGRATOR_PROD || "",
  "across-protocol": "",
  "synapse-protocol": "",
  "relay-protocol": "",
  "symbiosis-finance": "",
  "router-nitro": "",
  "orbiter-finance": "",
  "debridge-finance": "",
  "stargate-finance": "",
  "exodus-api": "",
  "coinbase-api": "",
  "binance-api": "",
  "lifi-api": "",
};

export const blockExplorers: Record<string, string> = {
  "1": "https://etherscan.io/tx/",
  "10": "https://optimistic.etherscan.io/tx/",
  "56": "https://bscscan.com/tx/",
  "137": "https://polygonscan.com/tx/",
  "250": "https://ftmscan.com/tx/",
  "8453": "https://basescan.org/tx/",
  "42161": "https://arbiscan.io/tx/",
  "43114": "https://snowtrace.io/tx/",
  "59144": "https://lineascan.build/tx/",
};

export const chains: Record<string, string> = {
  "1": "ethereum",
  "10": "optimism",
  "56": "binance",
  "137": "polygon",
  "250": "fantom",
  "252": "fraxtal",
  "314": "filecoin",
  "1284": "moonbeam",
  "2222": "kava",
  "5000": "mantle",
  "8453": "base",
  "13371": "immutable",
  "42161": "arbitrum",
  "42220": "celo",
  "43114": "avalanche",
  "59144": "linea",
  "81457": "blast",
  "534352": "scroll",
};

export const columnHeaders = [
  "Type",
  "Order Hash",
  "Order Refunded",
  "Order Filled",
  "Settlement Forwarded",
  "Settlement Processed",
  "Settlement Filled",
  "Tokens Released",
  "From Address",
  "To Address",
  "Filler",
  "From Token",
  "To Token",
  "Expiry",
  "From Amount",
  "Fill Amount",
  "Fee Rate",
  "From Chain",
  "To Chain",
  "From Token USD",
  "To Token USD",
  "Fee Value",
  "Post Hook Hash",
  "Calls",
];

export const stepLabels = [
  "Settlement Forwarded",
  "Settlement Processed",
  "Settlement Filled",
  "Tokens Released",
];

export const mockRequest: RouteRequest = {
  fromChain: "1",
  fromToken: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  fromAmount: "1000000000000000",
  toChain: "42161",
  toToken: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  quoteOnly: true,
};

export const currentTime = Math.floor(Date.now() / 1000);

export const getApiUrl = (): string => {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  switch (environment) {
    case "squid-prod":
      return ApiUrls.SQUID_PROD;
    case "squid-uat":
      return ApiUrls.SQUID_UAT;
    case "squid-dev":
      return ApiUrls.SQUID_DEV;
    case "squid-local":
      return ApiUrls.SQUID_LOCAL;
    case "across-protocol":
      return ApiUrls.ACROSS_PROTOCOL;
    case "synapse-protocol":
      return ApiUrls.SYNAPSE_PROTOCOL;
    case "relay-protocol":
      return ApiUrls.RELAY_PROTOCOL;
    case "symbiosis-protocol":
      return ApiUrls.SYMBIOSIS_FINANCE;
    case "router-nitro":
      return ApiUrls.ROUTER_NITRO;
    case "orbiter-finance":
      return ApiUrls.ORBITER_FINANCE;
    case "debridge-finance":
      return ApiUrls.DEBRIDGE_FINANCE;
    case "stargate-finance":
      return ApiUrls.STARGATE_FINANCE;
    case "exodus-api":
      return ApiUrls.EXODUS_API;
    case "coinbase-api":
      return ApiUrls.COINBASE_API;
    case "binance-api":
      return ApiUrls.BINANCE_API;
    case "lifi-api":
      return ApiUrls.LIFI_API;
    default:
      console.warn(`Unknown environment "${environment}", defaulting to local`);
      return ApiUrls.SQUID_LOCAL;
  }
};

export const getBlockExplorerUrl = (chainId: string, transactionHash: string): string => {
  return blockExplorers[chainId] ? `${blockExplorers[chainId]}${transactionHash}` : "#";
};

export const getAxelarUrl = (transactionHash: string): string => {
  return `https://axelarscan.io/gmp/${transactionHash}`;
};

export const getChainImage = (chain: string): string => {
  return `https://raw.githubusercontent.com/0xsquid/assets/main/images/chains/${chain}.svg`;
};

export const getTokenImage = (token: string): string => {
  return `https://raw.githubusercontent.com/0xsquid/assets/main/images/tokens/${token}.svg`;
};

export const getChainConfig = (chainId: string): StargateChainConfig => {
  const config = chainConfigs[chainId];
  return config;
};
