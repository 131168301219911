import { ethers } from "ethers";
import { hubAddress, spokeAddress } from "./constants";
import spokeAbi from "../abis/spoke.json";
import hubAbi from "../abis/hub.json";
import erc20 from "../abis/erc20.json";
import { ExtendedRFQEventData, RFQEventData } from "src/types";
import { computeFee, computeTokenUsdPrice } from "./formatting";

export function createSpokeContractInstance(signer: ethers.ContractRunner | null | undefined) {
  const contractAbi = spokeAbi;
  return new ethers.Contract(spokeAddress, contractAbi, signer);
}

export function createHubContractInstance(signer: ethers.ContractRunner | null | undefined) {
  const contractAbi = hubAbi;
  return new ethers.Contract(hubAddress, contractAbi, signer);
}

export function createTokenContractInstance(
  signer: ethers.ContractRunner | null | undefined,
  tokenAddress: string | ethers.Addressable,
) {
  const contractAbi = erc20;
  return new ethers.Contract(tokenAddress, contractAbi, signer);
}

export const determineEligibilityForRefund = (expiryUnix: string) => {
  const currentTime = Date.now() / 1000;
  const expiryTime = parseInt(expiryUnix);
  return currentTime > expiryTime + 24 * 60 * 60;
};

export const enrichOrderData = (tokens: any, order: RFQEventData): ExtendedRFQEventData => {
  const {
    order_fromToken,
    order_toToken,
    order_fromAmount,
    order_fillAmount,
    order_feeRate,
    order_fromChain,
    order_toChain,
  } = order.orderCreated?.data || {};

  const fromTokenId = `${order_fromChain}_${order_fromToken}`;
  const toTokenId = `${order_toChain}_${order_toToken}`;

  const fromTokenData = tokens.find(token => token.id === fromTokenId);
  const toTokenData = tokens.find(token => token.id === toTokenId);

  const usdValueFromToken = fromTokenData
    ? computeTokenUsdPrice(
        BigInt(order_fromAmount || "0"),
        fromTokenData.decimals,
        fromTokenData.usdPrice,
      )
    : "0";

  const usdValueToToken = toTokenData
    ? computeTokenUsdPrice(
        BigInt(order_fillAmount || "0"),
        toTokenData.decimals,
        toTokenData.usdPrice,
      )
    : "0";

  const feeInToken =
    order_feeRate && order_fromAmount ? computeFee(order_fromAmount, order_feeRate) : "0";

  const feeValue = fromTokenData
    ? computeTokenUsdPrice(BigInt(feeInToken), fromTokenData.decimals, fromTokenData.usdPrice)
    : "0";

  return {
    ...order,
    usdValueFromToken,
    usdValueToToken,
    feeValue,
  };
};
