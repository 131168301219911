import React from "react";
import { coralScanBannerStyle, topBannerStyle } from "../styles/styles";
import { Link } from "react-router-dom";

const TopBanner: React.FC = () => {
  return (
    <div style={topBannerStyle}>
      <Link to="/tx/" style={{ ...coralScanBannerStyle, textDecoration: "none" }}>
        <span
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          CORALSCAN
        </span>
      </Link>
      <strong>BETA IS NOW AVAILABLE!</strong>
    </div>
  );
};

export default TopBanner;
