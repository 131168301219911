import React, { useState, CSSProperties } from "react";
import { ExtendedRFQEventData } from "../types";
import { computeAllStatistics } from "../utils/statistics";

const Analytics: React.FC<{ rfqEvents: ExtendedRFQEventData[] }> = ({ rfqEvents }) => {
  const [activeTab, setActiveTab] = useState("orders");
  const stats = computeAllStatistics(rfqEvents);

  const tabStyles: CSSProperties = {
    display: "flex",
    justifyContent: "space-around",
    borderBottom: "1px solid #ddd",
    marginBottom: "10px",
  };

  const tabItemStyle = (isActive: boolean): CSSProperties => ({
    padding: "10px 20px",
    cursor: "pointer",
    borderBottom: isActive ? "3px solid #d5bef4" : "3px solid transparent",
    color: isActive ? "#d5bef4" : "#333",
    fontWeight: isActive ? "bold" : "normal",
  });

  const statisticsContainerStyle: CSSProperties = {
    padding: "10px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    height: "450px",
    overflowY: "auto",
  };

  const tableStyle: CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  };

  const thStyle: CSSProperties = {
    borderBottom: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#d5bef4",
    color: "#333",
    textAlign: "left",
  };

  const tdStyle: CSSProperties = {
    borderBottom: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  const sectionTitleStyle: CSSProperties = {
    marginTop: "20px",
    padding: "10px 0",
    borderBottom: "1px solid #ddd",
    fontWeight: "bold",
    fontSize: "16px",
  };

  const renderOrdersAnalysis = () => (
    <div>
      <div style={sectionTitleStyle}>User</div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Description</th>
            <th style={thStyle}>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tdStyle}>Total Orders</td>
            <td style={tdStyle}>{stats.userOrders.total}</td>
          </tr>
          <tr>
            <td style={tdStyle}>Completed Orders</td>
            <td style={tdStyle}>{stats.userOrders.completed}</td>
          </tr>
          <tr>
            <td style={tdStyle}>Refunded Orders</td>
            <td style={tdStyle}>{stats.userOrders.refunded}</td>
          </tr>
        </tbody>
      </table>

      <div style={sectionTitleStyle}>Filler</div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Description</th>
            <th style={thStyle}>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tdStyle}>Orders Filled</td>
            <td style={tdStyle}>{stats.fillerOrders.filled}</td>
          </tr>
          <tr>
            <td style={tdStyle}>Pending Hub Batching</td>
            <td style={tdStyle}>
              {Object.values(stats.fillerOrders.pendingHubBatching).reduce((a, b) => a + b, 0)}
            </td>
          </tr>
          <tr>
            <td style={tdStyle}>Pending Spoke Batching</td>
            <td style={tdStyle}>
              {Object.values(stats.fillerOrders.pendingSpokeBatching).reduce((a, b) => a + b, 0)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const renderAmountsAnalysis = () => (
    <div>
      <div style={sectionTitleStyle}>User Amounts</div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Chain</th>
            <th style={thStyle}>Token</th>
            <th style={thStyle}>Amount</th>
            <th style={thStyle}>USD Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(stats.userAmounts.perTokenPerChain).map(([chain, tokens]) => (
            <React.Fragment key={`user-${chain}`}>
              {Object.entries(tokens).map(([token, amount]) => (
                <tr key={`${chain}-${token}`}>
                  <td style={tdStyle}>{chain}</td>
                  <td style={tdStyle}>{token}</td>
                  <td style={tdStyle}>{amount.toFixed(2)}</td>
                  <td style={tdStyle}>
                    ${stats.userAmounts.perTokenPerChainUsdValue[chain][token]?.toFixed(2) || "0"}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <div style={sectionTitleStyle}>Filler Amounts</div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Chain</th>
            <th style={thStyle}>Token</th>
            <th style={thStyle}>Amount</th>
            <th style={thStyle}>USD Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(stats.fillerAmounts.perTokenPerChain).map(([chain, tokens]) => (
            <React.Fragment key={`filler-${chain}`}>
              {Object.entries(tokens).map(([token, amount]) => (
                <tr key={`${chain}-${token}`}>
                  <td style={tdStyle}>{chain}</td>
                  <td style={tdStyle}>{token}</td>
                  <td style={tdStyle}>{amount.toFixed(2)}</td>
                  <td style={tdStyle}>
                    ${stats.fillerAmounts.perTokenPerChainUsdValue[chain][token]?.toFixed(2) || "0"}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <nav style={tabStyles}>
        <div onClick={() => setActiveTab("orders")} style={tabItemStyle(activeTab === "orders")}>
          Orders
        </div>
        <div onClick={() => setActiveTab("amounts")} style={tabItemStyle(activeTab === "amounts")}>
          Order Amounts
        </div>
      </nav>

      <div style={statisticsContainerStyle}>
        {activeTab === "orders" && renderOrdersAnalysis()}
        {activeTab === "amounts" && renderAmountsAnalysis()}
      </div>
    </div>
  );
};

export default Analytics;
