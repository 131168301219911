import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  brandingStyle,
  iconStyle,
  searchContainerStyle,
  searchInputContainerStyle,
  searchIconStyle,
  notificationStyle,
  showNotificationStyle,
  nightToggleStyle,
  fullHeightStyle,
} from "../styles/styles";
import { IconMoon, IconMoonFilled, IconSearch } from "@tabler/icons-react";
import { isValidAddress, isValidTransactionHash } from "../utils/validation";
import TopBanner from "./TopBanner";

const TransactionSearch: React.FC = () => {
  const [hashInput, setHashInput] = useState("");
  const [focused, setFocused] = useState<boolean>(false);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isNightMode, setIsNightMode] = useState<boolean>(() => {
    const localStorageValue = localStorage.getItem("isNightMode");
    return localStorageValue !== null ? localStorageValue === "true" : true;
  });

  const toggleNightMode = () => {
    setIsNightMode(!isNightMode);
    localStorage.setItem("isNightMode", (!isNightMode).toString());
  };

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--bg-color", isNightMode ? "#1b212b" : "#fff");
    root.style.setProperty("--text-color", isNightMode ? "#fff" : "#333");
    root.style.setProperty("--text2-color", isNightMode ? "#333" : "#fff");
    root.style.setProperty("--time-color", isNightMode ? "#fff" : "#555");
    root.style.setProperty("--search-color", isNightMode ? "#666" : "#888");
    root.style.setProperty("--link-color", isNightMode ? "lightBlue" : "blue");
    root.style.setProperty("--accent-color", isNightMode ? "#e3f734" : "#d5bef4");
    root.style.setProperty("--accent2-color", isNightMode ? "#d5bef4" : "#e3f734");
    root.style.setProperty("--shadow-color", isNightMode ? "#13171f" : "#f9f9f9");

    if (localStorage.getItem("isNightMode") === null) {
      localStorage.setItem("isNightMode", "true");
    }
  }, [isNightMode]);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidTransactionHash(hashInput)) {
      navigate(`/tx/${hashInput}`);
    } else if (isValidAddress(hashInput)) {
      navigate(`/latest/${hashInput}`);
    } else {
      setNotificationMessage("Invalid transaction hash or address");
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
      inputRef.current?.focus();
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement !== inputRef.current) {
        setFocused(false);
      }
    }, 100);
  };

  const Notification = ({ message, visible }: { visible: boolean; message: string }) => (
    <div
      style={{
        ...notificationStyle,
        ...(visible ? showNotificationStyle : {}),
      }}
    >
      {message}
    </div>
  );

  return (
    <div style={fullHeightStyle}>
      <TopBanner />
      <div style={nightToggleStyle}>
        {isNightMode ? (
          <IconMoonFilled
            width={16}
            height={16}
            style={{ color: "#e3f734", cursor: "pointer", ...iconStyle }}
            onClick={toggleNightMode}
          />
        ) : (
          <IconMoon
            width={16}
            height={16}
            style={{ color: "#d5bef4", cursor: "pointer", ...iconStyle }}
            onClick={toggleNightMode}
          />
        )}
      </div>
      <div style={searchContainerStyle}>
        <Notification message={notificationMessage} visible={notificationVisible} />
        <div style={brandingStyle}>
          <img
            src="https://raw.githubusercontent.com/0xsquid/assets/refs/heads/main/squid-brand-assets/icon/squid-icon-yellow.png"
            alt="Brand Icon"
            style={iconStyle}
          />
          <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
            <span
              style={{
                marginTop: "1rem",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "var(--text-color)",
              }}
            >
              CORALSCAN
            </span>
            <span
              style={{
                fontSize: "0.75rem",
                fontWeight: "bold",
                color: "#d5bef4",
                marginTop: "2px",
              }}
            >
              BETA
            </span>
          </div>
        </div>
        <form onSubmit={handleSearchSubmit} style={searchInputContainerStyle}>
          <div
            style={{
              position: "relative",
              width: focused ? "600px" : "400px",
              transition: "width 0.3s ease-in-out",
            }}
          >
            <input
              ref={inputRef}
              type="text"
              onChange={e => setHashInput(e.target.value)}
              onFocus={() => setFocused(true)}
              onBlur={handleBlur}
              style={{
                width: "100%",
                padding: "10px 40px 10px 10px",
                borderRadius: "8px",
                border: `1px solid ${focused ? "var(--accent-color)" : "var(--text2-color)"}`,
                backgroundColor: "var(--bg-color)",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                color: "var(--text-color)",
                fontSize: "16px",
              }}
              placeholder="Search by tx hash, order hash, or address"
              value={hashInput}
            />
            {hashInput && (
              <div>
                <IconSearch
                  style={{
                    ...searchIconStyle,
                    color: "var(--search-color)",
                    cursor: "pointer",
                    position: "absolute",
                    right: "-40px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={handleSearchSubmit}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default TransactionSearch;
