import React, { useState } from "react";
import { IconArrowRight } from "@tabler/icons-react";
import {
  circleImageStyle,
  tokensItemStyle,
  environmentResultsStyle,
  benchmarkCircleImageStyle,
  benchmarkResultExpandedStyle,
  benchmarkResultRowStyle,
  benchmarkResultTransitionStyle,
} from "../styles/styles";
import { chains, EnvironmentMetadata, getChainImage } from "../utils/constants";
import {
  calculateTotalFeeCost,
  calculateUSDForToAmount,
  formatTokenAmount,
  getBridgeProvider,
} from "../utils/formatting";

interface BenchmarkResultsProps {
  results: Record<string, Record<string, any>>;
}

const BenchmarkResults: React.FC<BenchmarkResultsProps> = ({ results }) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const toggleRow = (key: string) => {
    setExpandedRow(prev => (prev === key ? null : key));
  };

  return (
    <div>
      {Object.entries(results).map(([pairKey, envResults]) => {
        const { from: fromToken, to: toToken } = JSON.parse(pairKey);

        if (!fromToken || !toToken) {
          console.error("Token data is missing", pairKey);
          return null;
        }

        const netUSDValues: Record<string, number> = {};
        let bestEnvName = "";
        let highestUSDValue = 0;

        for (const [envName, result] of Object.entries(envResults)) {
          if (result.status === "success") {
            const toAmountUSD = calculateUSDForToAmount(
              result.response.route.estimate.toAmount,
              toToken.usdPrice,
              toToken.decimals,
            );

            const usdValue = parseFloat(toAmountUSD);

            const totalFeeCost = calculateTotalFeeCost(
              result.response.route.estimate.feeCosts || [],
            );

            let netUSDValue = usdValue;

            if (
              (envName.includes("squid") &&
                getBridgeProvider(result.response.route.estimate.actions).toLowerCase() !==
                  "rfq") ||
              envName.includes("relay")
            ) {
              netUSDValue = usdValue - totalFeeCost;
            }

            netUSDValues[envName] = netUSDValue;
            if (netUSDValue > highestUSDValue) {
              highestUSDValue = netUSDValue;
              bestEnvName = envName;
            }
          }
        }

        const isRowExpanded = expandedRow === pairKey;

        return (
          <React.Fragment key={pairKey}>
            <div
              style={{
                ...tokensItemStyle,
                display: "flex",
                alignItems: "center",
                padding: "10px 0",
                width: "100%",
                cursor: "pointer",
                ...benchmarkResultRowStyle,
              }}
              onClick={() => toggleRow(pairKey)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "50%",
                  minWidth: "250px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginLeft: "10px",
                  }}
                >
                  <div style={circleImageStyle}>
                    <img
                      src={getChainImage(chains[fromToken.chainId])}
                      alt={chains[fromToken.chainId]}
                      style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                    />
                  </div>
                  <span style={{ marginLeft: "10px" }}>
                    {chains[fromToken.chainId]?.charAt(0).toUpperCase() +
                      chains[fromToken.chainId]?.slice(1)}
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
                  <div style={circleImageStyle}>
                    <img
                      src={fromToken.logoURI}
                      alt={fromToken.symbol}
                      style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                    />
                  </div>
                  <span style={{ marginLeft: "10px" }}>{fromToken.symbol}</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 15px",
                  }}
                >
                  <IconArrowRight />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={circleImageStyle}>
                    <img
                      src={getChainImage(chains[toToken.chainId])}
                      alt={chains[toToken.chainId]}
                      style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                    />
                  </div>
                  <span style={{ marginLeft: "10px" }}>
                    {chains[toToken.chainId]?.charAt(0).toUpperCase() +
                      chains[toToken.chainId]?.slice(1)}
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
                  <div style={circleImageStyle}>
                    <img
                      src={toToken.logoURI}
                      alt={toToken.symbol}
                      style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                    />
                  </div>
                  <span style={{ marginLeft: "10px" }}>{toToken.symbol}</span>
                </div>
              </div>

              {/* Results Section */}
              <div
                style={{
                  ...environmentResultsStyle,
                  width: "75%",
                  paddingLeft: "20px",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {Object.entries(envResults).map(([envName, result]) => {
                  const isBestResult = envName === bestEnvName;
                  const resultStyle = isBestResult ? { fontWeight: "bold", color: "green" } : {};

                  return (
                    <div
                      key={envName}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        ...resultStyle,
                        margin: "4px 0",
                      }}
                    >
                      <div style={{ ...benchmarkCircleImageStyle, marginBottom: "5px" }}>
                        <img
                          src={EnvironmentMetadata[envName]?.image}
                          alt={EnvironmentMetadata[envName]?.name}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <span
                        style={{
                          marginLeft: "8px",
                          fontWeight: "bold",
                          minWidth: "100px",
                          marginBottom: "5px",
                        }}
                      >
                        {EnvironmentMetadata[envName]?.name}:
                      </span>

                      <div
                        style={{
                          minWidth: "400px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {result.status === "success" ? (
                          <>
                            <span style={{ marginLeft: "8px", marginRight: "8px" }}>
                              Bridge:{" "}
                              {getBridgeProvider(result.response.route.estimate.actions) || "N/A"}
                            </span>
                            <IconArrowRight style={{ margin: "0 8px" }} />
                            <div
                              style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
                            >
                              <div style={circleImageStyle}>
                                <img
                                  src={toToken.logoURI}
                                  alt={toToken.symbol}
                                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                                />
                              </div>
                              <span style={{ marginLeft: "8px" }}>
                                {formatTokenAmount(
                                  result.response.route.estimate.toAmount,
                                  toToken.decimals,
                                )}
                              </span>
                              <span style={{ marginLeft: "5px" }}>{toToken.symbol}</span>
                            </div>
                            <span style={{ marginLeft: "8px" }}>
                              ($
                              {netUSDValues[envName]?.toFixed(2)})
                            </span>
                          </>
                        ) : (
                          <span style={{ marginLeft: "8px" }}>
                            {result.status} {result.message && ` - ${result.message}`}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Expanded Information */}
            <div
              style={{
                ...benchmarkResultExpandedStyle,
                ...benchmarkResultTransitionStyle,
                maxHeight: isRowExpanded ? "1000px" : "0",
                overflow: isRowExpanded ? "visible" : "hidden",
                opacity: isRowExpanded ? 1 : 0,
                margin: isRowExpanded ? "10px 0" : "0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: "space-around",
                }}
              >
                {Object.entries(envResults).map(
                  ([envName, result]) =>
                    result.status === "success" && (
                      <div
                        key={envName}
                        style={{
                          flex: "1 1 30%",
                          minWidth: "300px",
                          border: "1px solid var(--accent-color)",
                          borderRadius: "8px",
                          padding: "10px",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "4px",
                          }}
                        >
                          <div style={benchmarkCircleImageStyle}>
                            <img
                              src={EnvironmentMetadata[envName]?.image}
                              alt={EnvironmentMetadata[envName]?.name}
                              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                            />
                          </div>
                          <span style={{ marginLeft: "6px" }}>
                            <h5>{EnvironmentMetadata[envName]?.name}</h5>
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
                          <strong style={{ marginRight: "6px" }}>From:</strong>
                          <div style={benchmarkCircleImageStyle}>
                            <img
                              src={fromToken.logoURI}
                              alt={fromToken.symbol}
                              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                            />
                          </div>
                          <span style={{ marginLeft: "6px" }}>
                            {formatTokenAmount(
                              result.response.route.estimate.fromAmount,
                              fromToken.decimals,
                            )}
                            &nbsp;{fromToken.symbol} &nbsp;
                          </span>
                          <div style={benchmarkCircleImageStyle}>
                            <img
                              src={getChainImage(chains[fromToken.chainId])}
                              alt={chains[fromToken.chainId]}
                              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                            />
                          </div>
                          <span style={{ marginLeft: "6px" }}>
                            {chains[fromToken.chainId]?.charAt(0).toUpperCase() +
                              chains[fromToken.chainId]?.slice(1)}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
                          <strong style={{ marginRight: "6px" }}>To:</strong>
                          <div style={benchmarkCircleImageStyle}>
                            <img
                              src={toToken.logoURI}
                              alt={toToken.symbol}
                              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                            />
                          </div>
                          <span style={{ marginLeft: "6px" }}>
                            {formatTokenAmount(
                              result.response.route.estimate.toAmount,
                              toToken.decimals,
                            )}
                            &nbsp;{toToken.symbol} &nbsp;
                          </span>
                          <div style={benchmarkCircleImageStyle}>
                            <img
                              src={getChainImage(chains[toToken.chainId])}
                              alt={chains[toToken.chainId]}
                              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                            />
                          </div>
                          <span style={{ marginLeft: "6px" }}>
                            {chains[toToken.chainId]?.charAt(0).toUpperCase() +
                              chains[toToken.chainId]?.slice(1)}
                          </span>
                        </div>
                        <div style={{ marginBottom: "4px" }}>
                          <strong>Rate:</strong>&nbsp;1 {fromToken.symbol} ={" "}
                          {result.response.route.estimate.exchangeRate} {toToken.symbol}
                        </div>
                        <div style={{ color: "red", marginBottom: "4px" }}>
                          <strong>Fees:</strong>&nbsp;$
                          {calculateTotalFeeCost(result.response.route.estimate.feeCosts).toFixed(
                            2,
                          )}
                        </div>
                        <div
                          style={{
                            color: envName === bestEnvName ? "green" : undefined,
                            marginBottom: "4px",
                          }}
                        >
                          <strong>Net USD:</strong>&nbsp;$
                          {netUSDValues[envName]?.toFixed(2)}
                        </div>
                      </div>
                    ),
                )}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BenchmarkResults;
