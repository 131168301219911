import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { SquidRFQOrder } from "../types";
import { fetchLatestOrders } from "../services/api";
import { IconMoon, IconMoonFilled, IconSearch, IconClock } from "@tabler/icons-react";
import Spinner from "./TransactionDetails";
import TopBanner from "./TopBanner";
import {
  latestOrdersDataContainerStyle,
  latestOrdersItemStyle,
  headerContainerStyle,
  iconStyle,
  searchInputContainerStyle,
  nightToggleStyle,
  txContainerStyle,
  fullHeightStyle,
  detailsContainerStyle,
  loadMoreButtonStyle,
  notificationStyle,
  showNotificationStyle,
} from "../styles/styles";
import { getChainImage, getTokenImage, chains, currentTime } from "../utils/constants";
import {
  formatDateTime,
  formatElapsedTime,
  formatTokenAmount,
  formatTxHash,
} from "../utils/formatting";
import { isValidAddress, isValidTransactionHash } from "../utils/validation";
import Branding from "./Branding";

const Notification = ({ message, visible }: { visible: boolean; message: string }) => (
  <div
    style={{
      ...notificationStyle,
      ...(visible ? showNotificationStyle : {}),
    }}
  >
    {message}
  </div>
);

const Latest: React.FC = () => {
  const { address } = useParams<{ address: string }>();
  const navigate = useNavigate();

  const [orders, setOrders] = useState<SquidRFQOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [hasMoreOrders, setHasMoreOrders] = useState<boolean>(true);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");

  const [hashInput, setHashInput] = useState(address);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const orderIdSet = useRef<Set<string>>(new Set());
  const initialLoad = useRef<boolean>(false);

  const [isNightMode, setIsNightMode] = useState<boolean>(() => {
    const localStorageValue = localStorage.getItem("isNightMode");
    return localStorageValue !== null ? localStorageValue === "true" : true;
  });

  const toggleNightMode = () => {
    setIsNightMode(!isNightMode);
    localStorage.setItem("isNightMode", (!isNightMode).toString());
  };

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--bg-color", isNightMode ? "#1b212b" : "#fff");
    root.style.setProperty("--text-color", isNightMode ? "#fff" : "#333");
    root.style.setProperty("--text2-color", isNightMode ? "#333" : "#fff");
    root.style.setProperty("--time-color", isNightMode ? "#fff" : "#555");
    root.style.setProperty("--search-color", isNightMode ? "#666" : "#888");
    root.style.setProperty("--link-color", isNightMode ? "lightBlue" : "blue");
    root.style.setProperty("--accent-color", isNightMode ? "#e3f734" : "#d5bef4");
    root.style.setProperty("--accent2-color", isNightMode ? "#d5bef4" : "#e3f734");
    root.style.setProperty("--shadow-color", isNightMode ? "#13171f" : "#f9f9f9");

    if (localStorage.getItem("isNightMode") === null) {
      localStorage.setItem("isNightMode", "true");
    }
  }, [isNightMode]);

  const loadOrders = async (index: number = 0) => {
    try {
      setLoadingMore(index !== 0);
      const data = await fetchLatestOrders(index, "");

      if (data) {
        const uniqueNewOrders = data.filter(order => !orderIdSet.current.has(order.id));
        uniqueNewOrders.forEach(order => orderIdSet.current.add(order.id));

        if (uniqueNewOrders.length > 0) {
          setOrders(prevOrders => [...prevOrders, ...uniqueNewOrders]);
        }

        setHasMoreOrders(uniqueNewOrders.length >= 25);
      }
    } catch (error) {
      console.error("Error fetching latest orders:", error);
    } finally {
      setLoadingMore(false);
      if (index === 0) setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialLoad.current) {
      loadOrders(0);
      initialLoad.current = true;
    }
  }, []);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidTransactionHash(hashInput)) {
      navigate(`/tx/${hashInput}`);
    } else if (isValidAddress(hashInput)) {
      navigate(`/latest/${hashInput}`);
    } else {
      setNotificationMessage("Invalid transaction hash or address");
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
      inputRef.current?.focus();
    }
  };

  const circleImageStyle = {
    width: 24,
    height: 24,
    borderRadius: "50%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "8px",
  };

  const getStatus = (order: SquidRFQOrder) => {
    let elapsedTime: string;
    if (order.orderFilledTxHash) {
      elapsedTime = formatElapsedTime(
        order.orderFilledBlockTimestamp - order.orderCreatedBlockTimestamp,
      );
      return (
        <>
          <div
            style={{
              maxWidth: "100px",
              background: "green",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            Filled
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
            <IconClock size={16} style={{ marginRight: "4px" }} />
            <span>{elapsedTime}</span>
          </div>
        </>
      );
    } else if (order.orderRefundedTxHash) {
      return (
        <div
          style={{
            maxWidth: "100px",
            background: "red",
            color: "white",
            padding: "5px",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          Refunded
        </div>
      );
    } else {
      elapsedTime = formatElapsedTime(currentTime - order.orderCreatedBlockTimestamp);
      return (
        <>
          <div
            style={{
              maxWidth: "100px",
              background: "yellow",
              color: "black",
              padding: "5px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            Filling
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
            <IconClock size={16} style={{ marginRight: "4px" }} />
            <span>{elapsedTime}</span>
          </div>
        </>
      );
    }
  };

  return (
    <div style={fullHeightStyle}>
      <TopBanner />
      <Notification message={notificationMessage} visible={notificationVisible} />
      <div style={txContainerStyle}>
        <div style={nightToggleStyle}>
          {isNightMode ? (
            <IconMoonFilled
              width={16}
              height={16}
              style={{ color: "#e3f734", cursor: "pointer", ...iconStyle }}
              onClick={toggleNightMode}
            />
          ) : (
            <IconMoon
              width={16}
              height={16}
              style={{ color: "#d5bef4", cursor: "pointer", ...iconStyle }}
              onClick={toggleNightMode}
            />
          )}
        </div>
        <div style={headerContainerStyle}>
          <Branding />
          <form onSubmit={handleSearchSubmit} style={searchInputContainerStyle}>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                onChange={e => setHashInput(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setTimeout(() => setIsFocused(false), 100)}
                style={{
                  width: isFocused ? "600px" : "300px",
                  transition: "width 0.3s ease-in-out",
                  padding: "10px 40px 10px 10px",
                  borderRadius: "8px",
                  border: `1px solid ${isFocused ? "var(--accent-color)" : "var(--text2-color)"}`,
                  backgroundColor: "var(--bg-color)",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                  color: "var(--text-color)",
                  fontSize: "16px",
                  position: "absolute",
                  right: "0",
                }}
                placeholder="Search by tx hash, order hash, or address"
                value={hashInput}
              />
              {hashInput && (
                <IconSearch
                  style={{
                    color: "var(--search-color)",
                    cursor: "pointer",
                    position: "absolute",
                    right: 10,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={handleSearchSubmit}
                />
              )}
            </div>
          </form>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <div style={detailsContainerStyle}>
            <div style={latestOrdersDataContainerStyle}>
              <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: "var(--accent2-color)",
                  borderRadius: "8px",
                }}
              >
                <div style={{ flex: 1, textAlign: "left", marginLeft: "30px" }}>
                  <strong>Order Hash</strong>
                </div>
                <div style={{ flex: 1.5, textAlign: "left" }}>
                  <strong>Source</strong>
                </div>
                <div style={{ flex: 1.5, textAlign: "left" }}>
                  <strong>Destination</strong>
                </div>
                <div style={{ flex: 1, textAlign: "left" }}>
                  <strong>Status</strong>
                </div>
                <div style={{ flex: 1, textAlign: "left" }}>
                  <strong>Created At</strong>
                </div>
              </div>
              {orders.map(order => (
                <div key={order.id} style={latestOrdersItemStyle}>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    <Link to={`/tx/${order.id}`} style={{ color: "var(--link-color)" }}>
                      {formatTxHash(order.id)}
                    </Link>
                  </div>

                  <div style={{ flex: 1.5, textAlign: "left" }}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
                      <div style={circleImageStyle}>
                        <img
                          src={getChainImage(chains[order.fromChain || "unknown"])}
                          alt={order.fromChain}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      {chains[order.fromChain || "N/A"].charAt(0).toUpperCase() +
                        chains[order.fromChain || "unknown"].slice(1)}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={circleImageStyle}>
                        <img
                          src={getTokenImage(order.fromTokenSymbol.toLowerCase())}
                          alt={order.fromTokenSymbol}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      {formatTokenAmount(
                        order.fromAmount || "0",
                        (order.fromTokenDecimals !== undefined
                          ? order.fromTokenDecimals
                          : order.fromTokenSymbol === "USDC"
                            ? "6"
                            : "18"
                        ).toString(),
                      )}{" "}
                      {order.fromTokenSymbol}
                    </div>
                  </div>

                  <div style={{ flex: 1.5, textAlign: "left" }}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
                      <div style={circleImageStyle}>
                        <img
                          src={getChainImage(chains[order.toChain || "unknown"])}
                          alt={order.toChain}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      {chains[order.toChain || "N/A"].charAt(0).toUpperCase() +
                        chains[order.toChain || "unknown"].slice(1)}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={circleImageStyle}>
                        <img
                          src={getTokenImage(order.toTokenSymbol.toLowerCase())}
                          alt={order.toTokenSymbol}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      {formatTokenAmount(
                        order.fillAmount || "0",
                        (order.toTokenDecimals !== undefined
                          ? order.toTokenDecimals
                          : order.toTokenSymbol === "USDC"
                            ? "6"
                            : "18"
                        ).toString(),
                      )}{" "}
                      {order.toTokenSymbol}
                    </div>
                  </div>

                  <div style={{ flex: 1, textAlign: "left" }}>{getStatus(order)}</div>
                  <div style={{ flex: 1, textAlign: "left" }}>
                    {formatDateTime(order.orderCreatedBlockTimestamp)}
                  </div>
                </div>
              ))}

              {hasMoreOrders && (
                <button
                  style={loadMoreButtonStyle}
                  onClick={() => loadOrders(orders.length)}
                  disabled={loadingMore}
                >
                  {loadingMore ? "Loading..." : "Load More"}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Latest;
