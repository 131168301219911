import React, { useState } from "react";
import { defaultButtonStyle } from "../styles/styles";

const ButtonFeedback: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  style,
  ...props
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
    }, 150);
  };

  return (
    <button
      {...props}
      style={{
        ...defaultButtonStyle,
        ...style,
        ...(isClicked ? { transform: "scale(0.95)" } : {}),
      }}
      onClick={e => {
        handleClick();
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      {children}
    </button>
  );
};

export default ButtonFeedback;
