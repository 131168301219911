import React, { useState, useEffect, useRef } from "react";
import Branding from "./Branding";
import TopBanner from "./TopBanner";
import {
  fullHeightStyle,
  nightToggleStyle,
  iconStyle,
  tokenIconStyle,
  notificationStyle,
  showNotificationStyle,
  searchIconStyle,
  headerContainerStyle,
  searchInputContainerStyle,
  tokenSearchInputContainerStyle,
  tokensDataContainerStyle,
  tokensItemStyle,
  circleImageStyle,
  tokensContainerStyle,
  tokenFilterContainerStyle,
  chainDropdownStyle,
  chainCheckboxContainerStyle,
  tokenButtonStyle,
  tokenContainerWrapperStyle,
  environmentsDropdownStyle,
  environmentsCheckboxContainerStyle,
  environmentContainerStyle,
  resultsContainerWrapperStyle,
  progressContainerWrapperStyle,
  environmentButtonStyle,
} from "../styles/styles";
import { IconMoon, IconMoonFilled, IconSearch, IconX, IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { isValidAddress, isValidTransactionHash } from "../utils/validation";
import {
  Token,
  RouteRequest,
  AcrossResult,
  SynapseResult,
  RelayResult,
  FetchRelayParams,
  SymbiosisResult,
  FetchSymbiosisParams,
  FetchRouterNitroParams,
  FetchOrbiterParams,
  OrbiterQuote,
  DebridgeQuote,
  FetchDebridgeParams,
} from "../types";
import {
  fetchTokens,
  fetchRoute,
  fetchAcross,
  fetchSynapse,
  fetchRelay,
  fetchSymbiosis,
  fetchRouterNitro,
  fetchOrbiter,
  fetchDebridge,
  fetchStargate,
} from "../services/api";
import {
  chains,
  getChainImage,
  Environments,
  EnvironmentMetadata,
  EnvironmentIntegratorIds,
  getChainConfig,
} from "../utils/constants";
import BenchmarkResults from "./BenchmarkResults";
import { calculateTokenAmount, calculateUSDForToAmount } from "../utils/formatting";
import ProgressBar from "./ProgressBar";
import { ethers } from "ethers";

const Notification = ({ message, visible }: { visible: boolean; message: string }) => (
  <div
    style={{
      ...notificationStyle,
      ...(visible ? showNotificationStyle : {}),
    }}
  >
    {message}
  </div>
);

const tokenKey = (token: Token) => `${token.chainId}-${token.address}`;

const Benchmarker: React.FC = () => {
  const navigate = useNavigate();
  const [tokens, setTokens] = useState<Token[]>([]);
  const [filteredTokens, setFilteredTokens] = useState<Token[]>([]);
  const [hashInput, setHashInput] = useState("");
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [symbolFilter, setSymbolFilter] = useState<string[]>([]);
  const [chainFilter, setChainFilter] = useState<Record<string, boolean>>({});
  const [showChainDropdown, setShowChainDropdown] = useState<boolean>(false);
  const [showEnvironmentDropdown, setShowEnvironmentDropdown] = useState<boolean>(false);
  const [selectedEnvironments, setSelectedEnvironments] = useState<Record<string, boolean>>({});
  const [fromTokens, setFromTokens] = useState<Token[]>([]);
  const [toTokens, setToTokens] = useState<Token[]>([]);
  const [results, setResults] = useState<Record<string, Record<string, any>>>({});
  const [visibleFromButtons, setVisibleFromButtons] = useState<{ [key: string]: boolean }>({});
  const [visibleToButtons, setVisibleToButtons] = useState<{ [key: string]: boolean }>({});
  const [isBenchmarking, setIsBenchmarking] = useState<boolean>(false);
  const isBenchmarkingRef = useRef<boolean>(false);
  const [totalPairs, setTotalPairs] = useState<number>(0);
  const [completedPairs, setCompletedPairs] = useState<number>(0);
  const [usdAmountsInput, setUsdAmountsInput] = useState<string>("");

  const chainDropdownRef = useRef<HTMLDivElement | null>(null);
  const environmentDropdownRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isNightMode, setIsNightMode] = useState<boolean>(() => {
    const localStorageValue = localStorage.getItem("isNightMode");
    return localStorageValue !== null ? localStorageValue === "true" : true;
  });

  useEffect(() => {
    const defaultEnv = process.env.REACT_APP_ENVIRONMENT || "squid-local";
    setSelectedEnvironments({ [defaultEnv]: true });
  }, []);

  const toggleNightMode = () => {
    setIsNightMode(!isNightMode);
    localStorage.setItem("isNightMode", (!isNightMode).toString());
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (chainDropdownRef.current && !chainDropdownRef.current.contains(event.target as Node)) {
        setShowChainDropdown(false);
      }
      if (
        environmentDropdownRef.current &&
        !environmentDropdownRef.current.contains(event.target as Node)
      ) {
        setShowEnvironmentDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--bg-color", isNightMode ? "#1b212b" : "#fff");
    root.style.setProperty("--text-color", isNightMode ? "#fff" : "#333");
    root.style.setProperty("--text2-color", isNightMode ? "#333" : "#fff");
    root.style.setProperty("--time-color", isNightMode ? "#fff" : "#555");
    root.style.setProperty("--search-color", isNightMode ? "#666" : "#888");
    root.style.setProperty("--link-color", isNightMode ? "lightBlue" : "blue");
    root.style.setProperty("--accent-color", isNightMode ? "#e3f734" : "#d5bef4");
    root.style.setProperty("--accent2-color", isNightMode ? "#d5bef4" : "#e3f734");
    root.style.setProperty("--shadow-color", isNightMode ? "#13171f" : "#f9f9f9");

    if (localStorage.getItem("isNightMode") === null) {
      localStorage.setItem("isNightMode", "true");
    }
  }, [isNightMode]);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidTransactionHash(hashInput)) {
      navigate(`/tx/${hashInput}`);
    } else if (isValidAddress(hashInput)) {
      navigate(`/latest/${hashInput}`);
    } else {
      setNotificationMessage("Invalid transaction hash or address");
      setNotificationVisible(true);
      setTimeout(() => setNotificationVisible(false), 2000);
      inputRef.current?.focus();
    }
  };

  useEffect(() => {
    const loadTokens = async () => {
      try {
        const tokenResponse = await fetchTokens();

        if (tokenResponse && Array.isArray(tokenResponse)) {
          const filteredTokens = tokenResponse.filter(
            token => !token.symbol.includes(".axl") && /^\d+$/.test(token.chainId),
          );

          const uniqueTokensMap = new Map<string, Token>();

          filteredTokens.forEach(token => {
            uniqueTokensMap.set(tokenKey(token), token);
          });

          const uniqueTokens = Array.from(uniqueTokensMap.values());
          uniqueTokens.sort((a, b) => {
            if (a.chainId !== b.chainId) {
              return parseInt(a.chainId) - parseInt(b.chainId);
            }
            return a.address.localeCompare(b.address);
          });

          setTokens(uniqueTokens);
          setFilteredTokens(uniqueTokens);

          const initialChainFilter = uniqueTokens.reduce(
            (acc, token) => {
              acc[token.chainId] = true;
              return acc;
            },
            {} as Record<string, boolean>,
          );
          setChainFilter(initialChainFilter);
        }
      } catch (error) {
        console.error("Error loading tokens:", error);
      }
    };

    loadTokens();
  }, []);

  useEffect(() => {
    const filterTokens = () => {
      let filtered = tokens;

      if (symbolFilter.length > 0) {
        filtered = filtered.filter(token => symbolFilter.includes(token.symbol.toUpperCase()));
      }

      filtered = filtered.filter(token => chainFilter[token.chainId]);

      setFilteredTokens(filtered);
    };

    filterTokens();
  }, [symbolFilter, chainFilter, tokens]);

  useEffect(() => {
    const initialVisibility: { [key: string]: boolean } = {};
    tokens.forEach(token => {
      const tokenCompositeKey = tokenKey(token);
      initialVisibility[tokenCompositeKey] = true;
    });
    setVisibleFromButtons(initialVisibility);
    setVisibleToButtons(initialVisibility);
  }, [tokens]);

  const handleSymbolFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSymbolFilter(
      value
        .toUpperCase()
        .split(",")
        .map(token => token.trim())
        .filter(token => token),
    );
  };

  const toggleChainDropdown = () => {
    setShowChainDropdown(!showChainDropdown);
  };

  const toggleEnvironmentDropdown = () => {
    setShowEnvironmentDropdown(!showEnvironmentDropdown);
  };

  const handleChainFilterChange = (chainId: string) => {
    setChainFilter(prev => ({
      ...prev,
      [chainId]: !prev[chainId],
    }));

    setFilteredTokens(tokens.filter(token => chainFilter[token.chainId]));
  };

  const handleEnvironmentChange = (envName: string) => {
    setSelectedEnvironments(prev => ({
      ...prev,
      [envName]: !prev[envName],
    }));
  };

  const handleAddToList = (token: Token, type: "from" | "to") => {
    const tKey = tokenKey(token);

    const isTokenUnique = (tokensList: Token[]) => !tokensList.some(t => tokenKey(t) === tKey);

    if (type === "from" && isTokenUnique(fromTokens)) {
      setFromTokens([...fromTokens, token]);
      setVisibleFromButtons(prev => ({ ...prev, [tKey]: false }));
    } else if (type === "to" && isTokenUnique(toTokens)) {
      setToTokens([...toTokens, token]);
      setVisibleToButtons(prev => ({ ...prev, [tKey]: false }));
    }
  };

  const handleRemoveFromList = (token: Token, type: "from" | "to") => {
    const tKey = tokenKey(token);

    if (type === "from") {
      setFromTokens(fromTokens.filter(t => tokenKey(t) !== tKey));
      setVisibleFromButtons(prev => ({ ...prev, [tKey]: true }));
    } else if (type === "to") {
      setToTokens(toTokens.filter(t => tokenKey(t) !== tKey));
      setVisibleToButtons(prev => ({ ...prev, [tKey]: true }));
    }
  };

  const clearFromTokens = () => {
    setVisibleFromButtons(prev => {
      const updatedVisibility = { ...prev };
      fromTokens.forEach(token => {
        const tokenCompositeKey = tokenKey(token);
        updatedVisibility[tokenCompositeKey] = true;
      });
      return updatedVisibility;
    });
    setFromTokens([]);
  };

  const clearToTokens = () => {
    setVisibleToButtons(prev => {
      const updatedVisibility = { ...prev };
      toTokens.forEach(token => {
        const tokenCompositeKey = tokenKey(token);
        updatedVisibility[tokenCompositeKey] = true;
      });
      return updatedVisibility;
    });
    setToTokens([]);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement !== inputRef.current) {
        setIsFocused(false);
      }
    }, 100);
  };

  const handleUsdAmountsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsdAmountsInput(event.target.value);
  };

  const handleRunBenchmarking = async () => {
    if (isBenchmarking) {
      isBenchmarkingRef.current = false;
      setIsBenchmarking(false);
      return;
    }

    isBenchmarkingRef.current = true;
    setIsBenchmarking(true);

    setNotificationMessage("Running Benchmarking");
    setNotificationVisible(true);
    setTimeout(() => setNotificationVisible(false), 2000);

    const activeEnvironments = Object.keys(selectedEnvironments).filter(
      env => selectedEnvironments[env],
    );

    const validPairs = fromTokens.flatMap(fromToken =>
      toTokens
        .filter(toToken => fromToken.chainId !== toToken.chainId)
        .map(toToken => ({ fromToken, toToken })),
    );

    const usdValues = usdAmountsInput
      ? usdAmountsInput
          .split(",")
          .map(str => parseFloat(str.trim()))
          .filter(value => !isNaN(value))
      : [100];

    const totalRequests = validPairs.length * activeEnvironments.length * usdValues.length;
    setTotalPairs(totalRequests);
    setCompletedPairs(0);

    const controllers: AbortController[] = [];
    const initialResults: Record<string, Record<string, any>> = {};
    setResults(initialResults);

    for (const { fromToken, toToken } of validPairs) {
      if (!isBenchmarkingRef.current) {
        controllers.forEach(controller => controller.abort());
        return;
      }

      for (const targetUSD of usdValues) {
        const fromAmount = calculateTokenAmount(
          fromToken.usdPrice.toString(),
          fromToken.decimals.toString(),
          targetUSD,
        );

        for (const envName of activeEnvironments) {
          const controller = new AbortController();
          controllers.push(controller);

          const pairKey = JSON.stringify({ from: fromToken, to: toToken, targetUSD });
          const pairResult: Record<string, any> = {};

          try {
            if (envName === "across-protocol") {
              let fromTokenAddress = "";
              let toTokenAddress = "";

              if (fromToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
                fromTokenAddress = tokens.find(
                  token => token.chainId === fromToken.chainId && token.symbol === "WETH",
                ).address;
              } else {
                fromTokenAddress = fromToken.address;
              }

              if (toToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
                toTokenAddress = tokens.find(
                  token => token.chainId === toToken.chainId && token.symbol === "WETH",
                ).address;
              } else {
                toTokenAddress = toToken.address;
              }

              const acrossParams = {
                inputToken: fromTokenAddress,
                outputToken: toTokenAddress,
                originChainId: parseInt(fromToken.chainId),
                destinationChainId: parseInt(toToken.chainId),
                amount: fromAmount,
                skipAmountLimit: true,
              };

              const response = await fetchAcross(acrossParams);

              const relayFeeTotal = BigInt(response.relayFeeTotal);
              const fromAmountBigInt = BigInt(fromAmount);

              const toAmount = fromAmountBigInt - relayFeeTotal;
              const exchangeRate =
                parseFloat(toAmount.toString()) / parseFloat(fromAmountBigInt.toString());

              const feeCostsUSD = (
                (parseFloat(response.relayFeeTotal) * fromToken.usdPrice) /
                Math.pow(10, fromToken.decimals)
              ).toFixed(2);

              const acrossResult: AcrossResult = {
                route: {
                  estimate: {
                    fromAmount: fromAmountBigInt.toString(),
                    toAmount: toAmount.toString(),
                    exchangeRate: exchangeRate.toFixed(6),
                    feeCosts: [{ amountUsd: feeCostsUSD }],
                    actions: [{ type: "bridge", provider: "Across" }],
                  },
                },
              };

              pairResult[envName] = { status: "success", response: acrossResult };
            } else if (envName === "synapse-protocol") {
              const response = await fetchSynapse({
                originChainID: parseInt(fromToken.chainId),
                originTokenAddr: fromToken.address,
                destChainID: parseInt(toToken.chainId),
                destTokenAddr: toToken.address,
                relayerAddr: "",
              });

              const matchingQuotes = response.filter(
                q =>
                  q.origin_chain_id === parseInt(fromToken.chainId) &&
                  q.origin_token_addr.toLowerCase() === fromToken.address.toLowerCase() &&
                  q.dest_chain_id === parseInt(toToken.chainId) &&
                  q.dest_token_addr.toLowerCase() === toToken.address.toLowerCase(),
              );

              if (matchingQuotes.length > 0) {
                const bestQuote = matchingQuotes.reduce((prev, current) =>
                  BigInt(current.fixed_fee) < BigInt(prev.fixed_fee) ? current : prev,
                );

                const preFeeToAmount = BigInt(fromAmount);
                const fixedFeeBigInt = BigInt(bestQuote.fixed_fee);
                const toAmount = preFeeToAmount - fixedFeeBigInt;

                const fromAmountBigInt = BigInt(fromAmount);
                const exchangeRate =
                  fromAmountBigInt > BigInt(0)
                    ? (
                        parseFloat(toAmount.toString()) / parseFloat(fromAmountBigInt.toString())
                      ).toFixed(6)
                    : "0";

                const feeCostUSD = (
                  (parseFloat(fixedFeeBigInt.toString()) * toToken.usdPrice) /
                  Math.pow(10, toToken.decimals)
                ).toFixed(2);

                const synapseResult: SynapseResult = {
                  route: {
                    estimate: {
                      fromAmount: fromAmountBigInt.toString(),
                      toAmount: toAmount.toString(),
                      exchangeRate: exchangeRate,
                      feeCosts: [{ amountUsd: feeCostUSD }],
                      actions: [{ type: "bridge", provider: "Synapse" }],
                    },
                  },
                };

                pairResult[envName] = { status: "success", response: synapseResult };
              } else {
                pairResult[envName] = { status: "error", message: "No available quote found" };
              }
            } else if (envName === "relay-protocol") {
              try {
                let fromTokenAddress = "";
                let toTokenAddress = "";

                if (fromToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
                  fromTokenAddress = "0x0000000000000000000000000000000000000000";
                } else {
                  fromTokenAddress = fromToken.address;
                }

                if (toToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
                  toTokenAddress = "0x0000000000000000000000000000000000000000";
                } else {
                  toTokenAddress = toToken.address;
                }

                const relayParams: FetchRelayParams = {
                  user: "0xcccccccccccccccccccccccccccccccccccccccc",
                  originChainId: parseInt(fromToken.chainId),
                  destinationChainId: parseInt(toToken.chainId),
                  originCurrency: fromTokenAddress,
                  destinationCurrency: toTokenAddress,
                  amount: fromAmount,
                  tradeType: "EXACT_INPUT",
                };

                const response = await fetchRelay(relayParams);

                const toAmount = BigInt(response.details.currencyOut.amount);
                const fromAmountBigInt = BigInt(fromAmount);
                const exchangeRate = (
                  parseFloat(toAmount.toString()) / parseFloat(fromAmountBigInt.toString())
                ).toFixed(6);

                const totalFeeUSD = ["gas", "relayer", "relayerService", "app"]
                  .map(feeType => parseFloat(response.fees[feeType]?.amountUsd || "0"))
                  .reduce((acc, curr) => acc + curr, 0);

                const relayResult: RelayResult = {
                  route: {
                    estimate: {
                      fromAmount: fromAmountBigInt.toString(),
                      toAmount: toAmount.toString(),
                      exchangeRate: exchangeRate,
                      feeCosts: [{ amountUsd: totalFeeUSD.toFixed(2) }],
                      actions: [{ type: "bridge", provider: "Relay" }],
                    },
                  },
                };

                pairResult[envName] = { status: "success", response: relayResult };
              } catch (error) {
                pairResult[envName] = { status: "error", message: error.message };
              }
            } else if (envName === "symbiosis-finance") {
              try {
                const symbiosisParams: FetchSymbiosisParams = {
                  tokenAmountIn: {
                    amount: fromAmount,
                    address: fromToken.address,
                    symbol: fromToken.symbol,
                    chainId: parseInt(fromToken.chainId),
                    decimals: fromToken.decimals,
                  },
                  tokenOut: {
                    address: toToken.address,
                    symbol: toToken.symbol,
                    chainId: parseInt(toToken.chainId),
                    decimals: toToken.decimals,
                  },
                  to: "0xcccccccccccccccccccccccccccccccccccccccc",
                  from: "0xcccccccccccccccccccccccccccccccccccccccc",
                  slippage: 200,
                };

                const response = await fetchSymbiosis(symbiosisParams);

                const feeToken = tokens.find(
                  token =>
                    token.chainId === response.fee.chainId.toString() &&
                    token.address.toLowerCase() === response.fee.address.toLowerCase(),
                );

                if (!feeToken) {
                  throw new Error("Matching token for fee not found");
                }

                const feeAmountUsd = calculateUSDForToAmount(
                  response.fee.amount,
                  feeToken.usdPrice.toString(),
                  feeToken.decimals.toString(),
                );

                const toAmount = BigInt(response.tokenAmountOut.amount);
                const fromAmountBigInt = BigInt(fromAmount);
                const exchangeRate = (
                  parseFloat(toAmount.toString()) / parseFloat(fromAmountBigInt.toString())
                ).toFixed(6);

                const symbiosisResult: SymbiosisResult = {
                  route: {
                    estimate: {
                      fromAmount: fromAmountBigInt.toString(),
                      toAmount: toAmount.toString(),
                      exchangeRate: exchangeRate,
                      feeCosts: [{ amountUsd: feeAmountUsd }],
                      actions: [{ type: "bridge", provider: "Symbiosis" }],
                    },
                  },
                };

                pairResult[envName] = { status: "success", response: symbiosisResult };
              } catch (error) {
                pairResult[envName] = { status: "error", message: error.message };
              }
            } else if (envName === "router-nitro") {
              try {
                const routerNitroParams: FetchRouterNitroParams = {
                  amount: fromAmount,
                  fromTokenAddress: fromToken.address,
                  fromTokenChainId: fromToken.chainId,
                  toTokenAddress: toToken.address,
                  toTokenChainId: toToken.chainId,
                };

                const response = await fetchRouterNitro(routerNitroParams);

                const fromAmountBigInt = BigInt(fromAmount);
                const toAmount = BigInt(response.destination.tokenAmount);

                const bridgeFeeUsd = calculateUSDForToAmount(
                  response.bridgeFee.amount,
                  toToken.usdPrice.toString(),
                  toToken.decimals.toString(),
                );

                const exchangeRate = (
                  parseFloat(toAmount.toString()) / parseFloat(fromAmountBigInt.toString())
                ).toFixed(6);

                const routerNitroResult = {
                  route: {
                    estimate: {
                      fromAmount: fromAmountBigInt.toString(),
                      toAmount: toAmount.toString(),
                      exchangeRate,
                      feeCosts: [{ amountUsd: bridgeFeeUsd }],
                      actions: [{ type: "bridge", provider: "Nitro" }],
                    },
                  },
                };

                pairResult[envName] = { status: "success", response: routerNitroResult };
              } catch (error) {
                pairResult[envName] = { status: "error", message: error.message };
              }
            } else if (envName === "orbiter-finance") {
              try {
                const line = `${fromToken.chainId}/${toToken.chainId}-${fromToken.symbol}/${toToken.symbol}`;
                const orbiterParams: FetchOrbiterParams = {
                  line,
                  value: fromAmount,
                };

                const response: OrbiterQuote = await fetchOrbiter(orbiterParams);

                const toAmountBigInt = BigInt(response.result.receiveAmount);
                const fromAmountBigInt = BigInt(fromAmount);
                const exchangeRate = (
                  parseFloat(toAmountBigInt.toString()) / parseFloat(fromAmountBigInt.toString())
                ).toFixed(6);

                const withholdingFeeUSD = parseFloat(response.result.withholdingFeeAmount).toFixed(
                  2,
                );

                const orbiterResult = {
                  route: {
                    estimate: {
                      fromAmount: fromAmountBigInt.toString(),
                      toAmount: toAmountBigInt.toString(),
                      exchangeRate: exchangeRate,
                      feeCosts: [{ amountUsd: withholdingFeeUSD }],
                      actions: [{ type: "bridge", provider: "Orbiter" }],
                    },
                  },
                };

                pairResult[envName] = { status: "success", response: orbiterResult };
              } catch (error) {
                pairResult[envName] = { status: "error", message: error.message };
              }
            } else if (envName === "debridge-finance") {
              try {
                const debridgeParams: FetchDebridgeParams = {
                  srcChainId: fromToken.chainId,
                  srcChainTokenIn: fromToken.address,
                  srcChainTokenInAmount: fromAmount,
                  dstChainId: toToken.chainId,
                  dstChainTokenOut: toToken.address,
                  dstChainTokenOutAmount: "auto",
                };

                const response: DebridgeQuote = await fetchDebridge(debridgeParams);

                const toAmountBigInt = BigInt(response.estimation.dstChainTokenOut.amount);
                const fromAmountBigInt = BigInt(fromAmount);
                const exchangeRate = (
                  parseFloat(toAmountBigInt.toString()) / parseFloat(fromAmountBigInt.toString())
                ).toFixed(6);

                const feeCostsUSD = response.estimation.costsDetails.reduce((total, costDetail) => {
                  const feeAmount = BigInt(costDetail.payload.feeAmount);
                  const feeInUSD =
                    (parseFloat(feeAmount.toString()) * toToken.usdPrice) /
                    Math.pow(10, toToken.decimals);
                  return total + feeInUSD;
                }, 0);

                const debridgeResult = {
                  route: {
                    estimate: {
                      fromAmount: fromAmountBigInt.toString(),
                      toAmount: toAmountBigInt.toString(),
                      exchangeRate: exchangeRate,
                      feeCosts: [{ amountUsd: feeCostsUSD.toFixed(2) }],
                      actions: [{ type: "bridge", provider: "deBridge" }],
                    },
                  },
                };

                pairResult[envName] = { status: "success", response: debridgeResult };
              } catch (error) {
                pairResult[envName] = { status: "error", message: error.message };
              }
            } else if (envName === "stargate-finance") {
              try {
                const { rpcUrl: srcRpcUrl, address: srcAddress } = getChainConfig(
                  fromToken.chainId,
                );
                const { eid: dstEid } = getChainConfig(toToken.chainId);

                const stargateParams = {
                  sender: "0xcccccccccccccccccccccccccccccccccccccccc",
                  dstEid: dstEid,
                  receiver: ethers.ZeroHash,
                  amountSD: fromAmount,
                  composeMsg: "",
                  extraOptions: "",
                  payInLzToken: false,
                  target: srcAddress,
                };
                console.log("Stargate params", stargateParams);
                console.log("Stargate RPC", srcRpcUrl, "for chainId", fromToken.chainId);

                const stargateQuote = await fetchStargate(stargateParams, srcRpcUrl);

                console.log(
                  `Stargate Quote for ${fromToken.symbol} to ${toToken.symbol}:`,
                  stargateQuote,
                );

                pairResult[envName] = { status: "success", response: stargateQuote };
              } catch (error) {
                pairResult[envName] = { status: "error", message: error.message };
              }
            } else {
              const apiUrl = Environments[envName];
              const integratorId = EnvironmentIntegratorIds[envName] || "";

              const request: RouteRequest = {
                fromChain: fromToken.chainId,
                fromToken: fromToken.address,
                fromAmount,
                toChain: toToken.chainId,
                toToken: toToken.address,
                quoteOnly: true,
              };

              const response = await fetchRoute(request, apiUrl, integratorId, {
                signal: controller.signal,
              });
              pairResult[envName] = { status: "success", response };
            }
          } catch (error) {
            if (error.name === "AbortError") {
              console.warn("Request aborted.");
              continue;
            }
            pairResult[envName] = { status: "error", message: error.message };
          }

          setResults(prevResults => ({
            ...prevResults,
            [pairKey]: {
              ...prevResults[pairKey],
              [envName]: pairResult[envName],
            },
          }));

          setCompletedPairs(prevCompleted => prevCompleted + 1);
        }
      }
    }

    setNotificationMessage("Completed Benchmarking");
    setNotificationVisible(true);
    setTimeout(() => setNotificationVisible(false), 2000);

    setIsBenchmarking(false);
    isBenchmarkingRef.current = false;
  };

  return (
    <div style={fullHeightStyle}>
      <TopBanner />
      <Notification message={notificationMessage} visible={notificationVisible} />
      <div style={nightToggleStyle}>
        {isNightMode ? (
          <IconMoonFilled
            width={16}
            height={16}
            style={{ color: "#e3f734", cursor: "pointer", ...iconStyle }}
            onClick={toggleNightMode}
          />
        ) : (
          <IconMoon
            width={16}
            height={16}
            style={{ color: "#d5bef4", cursor: "pointer", ...iconStyle }}
            onClick={toggleNightMode}
          />
        )}
      </div>
      <div style={headerContainerStyle}>
        <Branding />
        <form onSubmit={handleSearchSubmit} style={searchInputContainerStyle}>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <input
              ref={inputRef}
              type="text"
              onChange={e => setHashInput(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={handleBlur}
              style={{
                width: isFocused ? "600px" : "300px",
                transition: "width 0.3s ease-in-out, left 0.3s ease-in-out",
                padding: "10px 40px 10px 10px",
                borderRadius: "8px",
                border: `1px solid ${isFocused ? "var(--accent-color)" : "var(--text2-color)"}`,
                backgroundColor: "var(--bg-color)",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                color: "var(--text-color)",
                fontSize: "16px",
                position: "absolute",
                right: "0",
              }}
              placeholder="Search by tx hash, order hash, or address"
              value={hashInput}
            />
            {hashInput && (
              <IconSearch
                style={{
                  ...searchIconStyle,
                  color: "var(--search-color)",
                  cursor: "pointer",
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                onClick={handleSearchSubmit}
              />
            )}
          </div>
        </form>
      </div>
      <div style={tokenContainerWrapperStyle}>
        <div style={tokenFilterContainerStyle}>
          <div style={{ position: "relative" }} ref={chainDropdownRef}>
            <button onClick={toggleChainDropdown} style={tokenButtonStyle}>
              <strong>Filter by Chain</strong>
            </button>
            {showChainDropdown && (
              <div style={chainDropdownStyle}>
                {Object.keys(chains).map(chainId => (
                  <div key={chainId} style={chainCheckboxContainerStyle}>
                    <input
                      type="checkbox"
                      checked={chainFilter[chainId]}
                      onChange={() => handleChainFilterChange(chainId)}
                    />
                    <div style={{ ...circleImageStyle, width: 16, height: 16, marginRight: "4px" }}>
                      <img
                        src={getChainImage(chains[chainId] || "unknown")}
                        alt={chains[chainId]}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <span>
                      {chains[chainId]?.charAt(0).toUpperCase() + chains[chainId]?.slice(1)}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div style={tokenSearchInputContainerStyle}>
            <input
              type="text"
              placeholder="Filter by symbols (e.g. USDC, USDT)"
              onChange={handleSymbolFilterChange}
              style={{
                padding: "8px",
                borderRadius: "8px",
                border: "1px solid var(--text2-color)",
                backgroundColor: "var(--bg-color)",
                color: "var(--text-color)",
                width: "300px",
              }}
            />
          </div>
        </div>
        <div style={tokensContainerStyle}>
          <div style={{ overflowY: "auto", maxHeight: "30vh" }}>
            <div style={tokensDataContainerStyle}>
              <div
                style={{ display: "flex", padding: "8px", backgroundColor: "var(--accent2-color)" }}
              >
                <strong style={{ flex: 1, marginLeft: "30px" }}>Chain</strong>
                <strong style={{ flex: 1 }}>Token</strong>
                <strong style={{ flex: 1 }}>Address</strong>
              </div>
              {filteredTokens.map(token => {
                const tKey = tokenKey(token);
                return (
                  <div key={tKey} style={tokensItemStyle}>
                    <div
                      style={{ flex: 1, display: "flex", alignItems: "center", marginLeft: "30px" }}
                    >
                      <div style={circleImageStyle}>
                        <img
                          src={getChainImage(chains[token.chainId] || "unknown")}
                          alt={chains[token.chainId]}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      {chains[token.chainId]?.charAt(0).toUpperCase() +
                        chains[token.chainId]?.slice(1)}
                    </div>
                    <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                      <div style={circleImageStyle}>
                        <img
                          src={token.logoURI}
                          alt={token.symbol}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      {token.symbol}
                    </div>
                    <div style={{ flex: 1 }}>{token.address}</div>
                    <button
                      onClick={() => handleAddToList(token, "from")}
                      style={{
                        ...tokenButtonStyle,
                        padding: "4px 8px",
                        fontSize: "12px",
                        visibility: visibleFromButtons[tKey] ? "visible" : "hidden",
                      }}
                    >
                      + From
                    </button>
                    <button
                      onClick={() => handleAddToList(token, "to")}
                      style={{
                        ...tokenButtonStyle,
                        padding: "4px 8px",
                        fontSize: "12px",
                        visibility: visibleToButtons[tKey] ? "visible" : "hidden",
                      }}
                    >
                      + To
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div style={tokenContainerWrapperStyle}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "50%",
              padding: "10px",
              height: "20vh",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              flexDirection: "column",
              borderRight: "1px solid var(--text2-color)",
              borderBottomRightRadius: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "45px",
              }}
            >
              <h3 style={{ color: "var(--text-color)", marginRight: "10px" }}>From Tokens</h3>
              <IconTrash
                style={{ color: "var(--accent-color)", cursor: "pointer" }}
                onClick={clearFromTokens}
              />
            </div>
            <div style={{ flex: 1, overflowY: "auto" }}>
              {fromTokens.length > 0 ? (
                fromTokens.map((token, index) => (
                  <div
                    key={`${index}-${tokenKey(token)}`}
                    style={{
                      ...tokensItemStyle,
                      display: "flex",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "30px",
                      }}
                    >
                      <div style={circleImageStyle}>
                        <img
                          src={getChainImage(chains[token.chainId] || "unknown")}
                          alt={chains[token.chainId]}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        {chains[token.chainId]?.charAt(0).toUpperCase() +
                          chains[token.chainId]?.slice(1)}
                      </div>
                    </div>
                    <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                      <div style={circleImageStyle}>
                        <img
                          src={token.logoURI}
                          alt={token.symbol}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <div style={{ marginLeft: "10px" }}>{token.symbol}</div>
                    </div>
                    <IconX
                      style={{ color: "#e3f734", cursor: "pointer", ...tokenIconStyle }}
                      onClick={() => handleRemoveFromList(token, "from")}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    color: "var(--text-color)",
                    textAlign: "center",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90%",
                  }}
                >
                  Empty
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              marginLeft: "5px",
              width: "50%",
              padding: "10px",
              height: "20vh",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              flexDirection: "column",
              borderLeft: "1px solid var(--text2-color)",
              borderBottomLeftRadius: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "45px",
              }}
            >
              <h3 style={{ color: "var(--text-color)", marginRight: "10px" }}>To Tokens</h3>
              <IconTrash
                style={{ color: "var(--accent-color)", cursor: "pointer" }}
                onClick={clearToTokens}
              />
            </div>
            <div style={{ flex: 1, overflowY: "auto" }}>
              {toTokens.length > 0 ? (
                toTokens.map((token, index) => (
                  <div
                    key={`${index}-${tokenKey(token)}`}
                    style={{
                      ...tokensItemStyle,
                      display: "flex",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "30px",
                      }}
                    >
                      <div style={circleImageStyle}>
                        <img
                          src={getChainImage(chains[token.chainId] || "unknown")}
                          alt={chains[token.chainId]}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        {chains[token.chainId]?.charAt(0).toUpperCase() +
                          chains[token.chainId]?.slice(1)}
                      </div>
                    </div>
                    <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                      <div style={circleImageStyle}>
                        <img
                          src={token.logoURI}
                          alt={token.symbol}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <div style={{ marginLeft: "10px" }}>{token.symbol}</div>
                    </div>
                    <IconX
                      style={{ color: "#e3f734", cursor: "pointer", ...tokenIconStyle }}
                      onClick={() => handleRemoveFromList(token, "to")}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    color: "var(--text-color)",
                    textAlign: "center",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90%",
                  }}
                >
                  Empty
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            ...environmentContainerStyle,
            borderTop: "1px solid var(--accent-color)",
          }}
        >
          <div style={{ position: "relative" }} ref={environmentDropdownRef}>
            <input
              type="text"
              placeholder="Enter USD amounts (e.g. 1, 10, 100, default: 100)"
              value={usdAmountsInput}
              onChange={handleUsdAmountsChange}
              style={{
                width: "290px",
                padding: "8px",
                borderRadius: "8px",
                border: "1px solid var(--text2-color)",
                backgroundColor: "var(--bg-color)",
                color: "var(--text-color)",
                marginRight: "10px",
              }}
            />
            <button onClick={toggleEnvironmentDropdown} style={environmentButtonStyle}>
              <strong>Environments</strong>
            </button>
            {showEnvironmentDropdown && (
              <div style={environmentsDropdownStyle}>
                {Object.entries(Environments).map(([envName, _url]) => {
                  const isEnabled = EnvironmentMetadata[envName]?.enabled;
                  return (
                    <div key={envName} style={environmentsCheckboxContainerStyle}>
                      <input
                        type="checkbox"
                        checked={selectedEnvironments[envName] === true}
                        onChange={() => handleEnvironmentChange(envName)}
                        disabled={!isEnabled}
                      />
                      <div
                        style={{
                          ...circleImageStyle,
                          width: 16,
                          height: 16,
                          marginRight: "4px",
                          opacity: isEnabled ? 1 : 0.2,
                        }}
                      >
                        <img
                          src={EnvironmentMetadata[envName]?.image}
                          alt={EnvironmentMetadata[envName]?.name}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <span
                        style={{
                          opacity: isEnabled ? 1 : 0.2,
                        }}
                      >
                        {EnvironmentMetadata[envName]?.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <button onClick={handleRunBenchmarking} style={tokenButtonStyle}>
            <strong>{isBenchmarking ? "Stop Benchmarking" : "Run Benchmarking"}</strong>
          </button>
        </div>
      </div>
      <div style={{ ...progressContainerWrapperStyle, maxHeight: "3vh" }}>
        {isBenchmarking && <ProgressBar progress={(completedPairs / totalPairs) * 100} />}
      </div>
      <div style={{ ...resultsContainerWrapperStyle, maxHeight: "93vh" }}>
        <BenchmarkResults results={results} />
      </div>
    </div>
  );
};

export default Benchmarker;
