import React from "react";
import { progressBarContainerStyle, progressStyle, progressLabelStyle } from "../styles/styles";

const ProgressBar: React.FC<{ progress: number }> = ({ progress }) => (
  <div style={{ position: "relative", width: "150px" }}>
    <div style={progressLabelStyle}>{`${progress.toFixed(2)}%`}</div>
    <div style={progressBarContainerStyle}>
      <div style={{ ...progressStyle, width: `${progress}%` }} />
    </div>
  </div>
);

export default ProgressBar;
