import React, { useState, useRef } from "react";
import { tooltipStyle } from "../styles/styles";

interface TooltipProps {
  text: React.ReactNode;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const showTooltip = (event: React.MouseEvent<HTMLDivElement>) => {
    if (tooltipRef.current) {
      const rect = tooltipRef.current.getBoundingClientRect();
      setPosition({
        x: event.clientX - rect.width / 2,
        y: event.clientY - rect.height - 10,
      });
    }
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={{ display: "inline-block", position: "relative" }}
    >
      {children}
      {visible && (
        <div ref={tooltipRef} style={{ ...tooltipStyle, top: position.y, left: position.x }}>
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
