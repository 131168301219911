import { OrderCreatedEvent } from "src/types";

export const isTokenSupported = (tokens: any, token: string | undefined): boolean => {
  return tokens.some((t: { address: string }) => t.address === token);
};

export function isOrderCreatedEvent(event: any): event is OrderCreatedEvent {
  return (
    event.type === "OrderCreated" &&
    event.orderHash &&
    event.data &&
    typeof event.data.order_fromAddress === "string"
  );
}

export const isValidTransactionHash = (hash: string): boolean => {
  return /^0x[a-fA-F0-9]{64}$/.test(hash);
};

export const isValidAddress = (address: string): boolean => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};
