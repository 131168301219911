let integratorId: string | null = process.env.REACT_APP_INTEGRATOR_ID || null;
let txIntegratorId: string | null = process.env.REACT_APP_TX_INTEGRATOR_ID || null;

export const setIntegratorId = (id: string) => {
  integratorId = id;
};

export const getIntegratorId = (): string => {
  return integratorId || "";
};

export const getScanIntegratorId = (): string => {
  return txIntegratorId || "";
};
