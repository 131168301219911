import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import RFQ from "./components/RFQ";
import Authenticator from "./components/Authenticator";
import TransactionDetails from "./components/TransactionDetails";
import TransactionSearch from "./components/TransactionSearch";
import LatestOrders from "./components/LatestOrders";
import Latest from "./components/Latest";
import Benchmarker from "./components/Benchmarker";
import BenchmarkerAuthenticator from "./components/BenchmarkerAuthenticator";

declare global {
  interface Window {
    ethereum?: any;
  }
}

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div>
        <main>
          <Routes>
            <Route path="/" element={<TransactionSearch />} />
            <Route path="/tx" element={<TransactionSearch />} />
            <Route path="/tx/:transactionHash" element={<TransactionDetails />} />
            <Route path="/latest" element={<Latest />} />
            <Route path="/latest/:address" element={<LatestOrders />} />
            <Route
              path="/dashboard"
              element={
                <Authenticator>
                  <RFQ />
                </Authenticator>
              }
            />
            <Route
              path="/benchmarker"
              element={
                <BenchmarkerAuthenticator>
                  <Benchmarker />
                </BenchmarkerAuthenticator>
              }
            />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default App;
